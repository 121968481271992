export default () => ({
  topicsList: [],
  topicDetail: {},
  myTeamsList: [],
  teamsList: [],
  teamsViewSelector: 1,
  teamDetail: {},
  restrictJoinTeams: true,
  discussionList: []
});
