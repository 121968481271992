export default {
  // TODO:: We can reuse the below logic for unit completion later
  // unitsBySequenceId: state => state.unitsBySequenceId,
  unitsBySequenceId: (state, getters) => {
    if (
      state.unitsBySequenceId.length === 0 ||
      !getters.getCurrentSequence.is_timed_assessment
    ) {
      return getters.getCurrentSequence && getters.getCurrentSequence.units
        ? getters.getCurrentSequence.units
        : [];
    } else {
      // unitsBySequenceId gets updated when we submit a timed assessment.
      return state.unitsBySequenceId;
    }
  },
  scormStatus: state => state.scormStatus,
  iframeContentStatus: state => state.iframeContentStatus,
  getCurrentSequenceId: (state, getters) => {
    // check if we have `currentSequenceId` set
    if (state.currentSequenceId !== "") {
      return state.currentSequenceId;
    }
    // handle on page load
    let sequenceId = "";
    if (
      getters.getSelectedCourse &&
      getters.getSelectedCourse.sections &&
      getters.getSelectedCourse.sections.length > 0
    ) {
      let resumeBlockIndex = getters.getSelectedCourse.sections.findIndex(
        s => s.resumeBlock === true
      );

      // resume
      if (resumeBlockIndex !== -1) {
        let resumeSecIndex = getters.getSelectedCourse.sections[
          resumeBlockIndex
        ].sequences.findIndex(s => s.resumeBlock);
        sequenceId =
          getters.getSelectedCourse.sections[resumeBlockIndex].sequences[
            resumeSecIndex !== -1 ? resumeSecIndex : 0
          ].id;
      } else {
        // load the first section
        let resumeSecIndex = getters.getSelectedCourse.sections[0].sequences.findIndex(
          s => s.resumeBlock
        );
        if (getters.getSelectedCourse.sections[0].sequences.length > 0) {
          sequenceId =
            getters.getSelectedCourse.sections[0].sequences[
              resumeSecIndex !== -1 ? resumeSecIndex : 0
            ].id;
        }
      }
    }

    return sequenceId;
  },
  getCurrentSequence: (state, getters) => {
    return getSequenceById(
      getters.getSelectedCourse,
      getters.getCurrentSequenceId
    );
  },
  getCurrentSection: (state, getters) => {
    return getSectionBySequenceId(
      getters.getSelectedCourse,
      getters.getCurrentSequenceId
    );
  },
  getNextSection: (state, getters) => {
    if (getters.isLastSequence === false) {
      return getSectionBySequenceId(
        getters.getSelectedCourse,
        getters.getNextSequenceId
      );
    }

    return null;
  },
  getNextSequenceId: (state, getters) => {
    if (getters.isLastSequence === false) {
      return getters.getAllSequenceIds[getters.getCurrentSequenceIndex + 1];
    }
    return null;
  },
  getNextSequence: (state, getters) => {
    if (getters.isLastSequence === false) {
      return getSequenceById(
        getters.getSelectedCourse,
        getters.getNextSequenceId
      );
    }

    return null;
  },
  getPreviousSection: (state, getters) => {
    if (getters.isFirstSequence === false) {
      return getSectionBySequenceId(
        getters.getSelectedCourse,
        getters.getPreviousSequenceId
      );
    }

    return null;
  },
  getPreviousSequenceId: (state, getters) => {
    if (getters.isFirstSequence === false) {
      return getters.getAllSequenceIds[getters.getCurrentSequenceIndex - 1];
    }
    return null;
  },
  getPreviousSequence: (state, getters) => {
    if (getters.isFirstSequence === false) {
      return getSequenceById(
        getters.getSelectedCourse,
        getters.getPreviousSequenceId
      );
    }

    return null;
  },
  getCurrentSequenceIndex: (state, getters) => {
    let allSequenceIds = getters.getAllSequenceIds;
    return allSequenceIds.indexOf(getters.getCurrentSequenceId);
  },
  // TODO get lesson/unit index under each sequence in module
  getCourseUnitIndex: (state, getters) => {
    return (
      getters.getAllUnitIds.findIndex(id => id === getters.getCurrentUnitId) + 1
    );
  },
  isLastSequence: (state, getters) => {
    return (
      getters.getCurrentSequenceIndex === getters.getAllSequenceIds.length - 1
    );
  },
  isFirstSequence: (state, getters) => {
    return getters.getCurrentSequenceIndex === 0;
  },
  getCurrentUnit: (state, getters) => {
    return getters.getAllUnits[
      getters.getAllUnits.findIndex(
        unit => unit.id === getters.getCurrentUnitId
      )
    ];
  },
  getNextUnit: (state, getters) => {
    return getters.getAllUnits[
      getters.getAllUnits.findIndex(
        unit => unit.id === getters.getCurrentUnitId
      ) < getters.getAllUnits.length
        ? getters.getAllUnits.findIndex(
            unit => unit.id === getters.getCurrentUnitId
          ) + 1
        : getters.getAllUnits.length
    ];
  },
  getPreviousUnit: (state, getters) => {
    return getters.getAllUnits[
      getters.getAllUnits.findIndex(
        unit => unit.id === getters.getCurrentUnitId
      ) > 0
        ? getters.getAllUnits.findIndex(
            unit => unit.id === getters.getCurrentUnitId
          ) - 1
        : 0
    ];
  },
  getCurrentUnitIndex: (state, getters) => {
    return getters.unitsBySequenceId.findIndex(
      u => u.id === getters.getCurrentUnitId
    );
  },
  isFirstUnit: (state, getters) => {
    return getters.getCurrentUnitIndex === 0;
  },
  isLastUnit: (state, getters) => {
    return getters.getCurrentUnitIndex === getters.unitsBySequenceId.length - 1;
  },
  isAllSectionsCompleted: (state, getters) => {
    let isCompleted = true;
    getters.getSelectedCourse.sections.forEach(s => {
      if (s.complete === false) {
        isCompleted = false;
      }
    });
    return isCompleted;
  },
  getCurrentUnitId: (state, getters) => {
    // check if we have `currentUnitId` set
    if (state.currentUnitId !== "") {
      return state.currentUnitId;
    }

    // handle on section click
    let unitId = "";
    if (getters.unitsBySequenceId && getters.unitsBySequenceId.length > 0) {
      let resumeBlockIndex = getters.unitsBySequenceId.findIndex(
        s => s.resumeBlock === true
      );

      // resume
      if (resumeBlockIndex !== -1) {
        unitId = getters.unitsBySequenceId[resumeBlockIndex].id;
      } else {
        // load the first unit
        unitId = getters.unitsBySequenceId[0].id;
      }
    }
    return unitId;
  },
  isCurrentUnitCompleted: (state, getters) => {
    return (
      getters.unitsBySequenceId[getters.getCurrentUnitIndex].complete &&
      getters.unitsBySequenceId[getters.getCurrentUnitIndex].complete === true
    );
  },
  getCourseMilestones: (state, getters) => {
    let milestones = [];
    getters.getSelectedCourse.sections.forEach(section => {
      milestones.push({
        type: "major",
        isLocked: false,
        isCompleted: section.complete
      });

      section.sequences.forEach(sequence => {
        milestones.push({
          type: "minor",
          isLocked:
            getters.isPrerequisiteCompleted(sequence) === true ? false : true,
          isCompleted: sequence.complete
        });
      });
    });

    return milestones;
  },
  getCourseCompletionPercentage: (state, getters) => {
    let totalSectionsCount = getters.getSelectedCourse.sections.length;
    let completedSectionsCount = getters.getSelectedCourse.sections.reduce(
      (count, section) => (section.complete === true ? count + 1 : count),
      0
    );

    return (completedSectionsCount / totalSectionsCount) * 100;
  },
  isPrerequisiteCompleted: (state, getters) => module => {
    let isCompleted = true;

    if (module && module.prerequisite && module.prerequisite !== null) {
      // Loop through all the sequences across all sections
      // and find the completion status of the prerequisite
      // sequence
      isCompleted = getters.getSelectedCourse.sections.some(m => {
        return m.sequences.some(s => {
          if (s.id === module.prerequisite) {
            return s.complete;
          }
        });
      });
    }

    return isCompleted;
  },
  getAllSequenceIds(state, getters) {
    let ids = [];
    if (getters.getSelectedCourse.sections) {
      getters.getSelectedCourse.sections.forEach(s => {
        ids = [...ids, ...s.sequences.map(a => a.id)];
      });
    }
    return ids;
  },
  getAllUnitIds(state, getters) {
    let ids = [];
    if (getters.getSelectedCourse.sections) {
      getters.getSelectedCourse.sections.forEach(m => {
        m.sequences.forEach(s => {
          ids = [...ids, ...s.units.map(u => u.id)];
        });
      });
    }
    return ids;
  },
  getAllUnits(state, getters) {
    let units = [];
    if (getters.getSelectedCourse.sections) {
      getters.getSelectedCourse.sections.forEach(m => {
        m.sequences.forEach(s => {
          units = [...units, ...s.units];
        });
      });
    }
    return units;
  },
  getGradedFlag(state, getters) {
    return getters.getCurrentSequence.is_graded;
  },
  getCurrentMenuItem: state => state.currentMenuItem,
  getModuleToCheckMC: state => state.moduleToCheckMC,
  isVleSideNavOpen: state => state.expandVleSideNav,
  isScormUnit(state, getters) {
    return (
      getters.getCurrentUnit?.type.toUpperCase() === "SCORM_V2" ||
      getters.getCurrentUnit?.type.toUpperCase() === "CMI5"
    );
  }
};

function getSequenceById(selectedCourse, id) {
  return getSectionOrSequenceBySequenceId(selectedCourse, id, "sequence");
}

function getSectionBySequenceId(selectedCourse, id) {
  return getSectionOrSequenceBySequenceId(selectedCourse, id, "section");
}

function getSectionOrSequenceBySequenceId(selectedCourse, id, type) {
  let sectionOrSequence = {};
  if (
    selectedCourse &&
    selectedCourse.sections &&
    selectedCourse.sections.length > 0
  ) {
    selectedCourse.sections.some(m => {
      return m.sequences.some(s => {
        if (s.id === id) {
          if (type === "sequence") {
            sectionOrSequence = s;
          } else if (type === "section") {
            sectionOrSequence = m;
          }
          return true;
        }
      });
    });
  }
  return sectionOrSequence;
}
