import axios from "axios";

axios.defaults.headers.common["Accept-Language"] = "en";

const getAuthHeader = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

export function getURL(baseURL, params) {
  return {
    // GET
    GET_ALL_POST_LIST: `${baseURL}/discussions/${params.course_id}/posts?page=${params.page}`,
    GET_ALL_DRAFT_POST_LIST: `${baseURL}/discussions/${params.course_id}/posts?is_draft=true`,
    GET_POST_LIST: `${baseURL}/discussions/${params.course_id}/topics/${params.topic_id}/posts?page=${params.page}`,
    GET_DRAFT_POST_LIST: `${baseURL}/discussions/${params.course_id}/topics/${params.topic_id}/posts?is_draft=true`,
    GET_POST_DETAILS: `${baseURL}/discussions/posts/${params.postId}`,
    GET_DRAFT_POST_DETAILS: `${baseURL}/discussions/posts/${params.postId}?is_draft=true`,
    GET_POST_RESPONSES: `${baseURL}/discussions/posts/${params.postId}/responses?page=${params.page}`,
    GET_POST_RESPONSE_COMMENTS: `${baseURL}/discussions/responses/${params.responseId}/comments?page=${params.page}`,
    GET_COURSE_USERS_LIST: `${baseURL}/discussions/${params.course_id}/users`,

    // POST
    CREATE_POST_RESPONSE: `${baseURL}/discussions/responses`,
    CREATE_RESPONSE_COMMENT: `${baseURL}/discussions/comments`,
    CREATE_RESPONSE_VOTE: `${baseURL}/discussions/${params.courseId}/responses/${params.responseId}/vote`,
    CREATE_POST_VOTE: `${baseURL}/discussions/${params.courseId}/posts/${params.postId}/vote`,

    //PUT
    UPDATE_POST_RESPONSE: `${baseURL}/discussions/responses/${params.responseId}`,

    //DELETE
    DELETE_RESPONSE_VOTE: `${baseURL}/discussions/${params.courseId}/responses/${params.responseId}/vote`,
    DELETE_POST_VOTE: `${baseURL}/discussions/${params.courseId}/posts/${params.postId}/vote`,
    DELETE_POST_RESPONSE: `${baseURL}/discussions/responses/${params.responseId}`,

    //TOXIC LANGUAGE
    TOXIC_LANGUAGE_URL: `${baseURL}/toxicity/detect`
  };
}

export default {
  // GET
  getListOfAllPosts({ commit, rootState }, params) {
    commit("SET_LIST_POST_LOADING", true);
    let URL = getURL(rootState.config.baseURL, params).GET_ALL_POST_LIST;
    if (params.text) {
      URL = URL + `&text=${params.text}`;
    }
    if (params.sort_key) {
      URL = URL + `&sort_key=${params.sort_key}&sort_order=desc`;
    }
    if (params.flagged) {
      URL = URL + `&flagged=true`;
    }
    if (params.unread) {
      URL = URL + `&unread=true`;
    }
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        if (
          !params.text &&
          !params.sort_key &&
          !params.flagged &&
          !params.unread
        ) {
          commit("SET_ALL_LIST_COUNT", response.data.data.count);
        }
        commit("SET_LIST_POST", response.data.data);
        commit("SET_LIST_POST_LOADING", false);
        //Check if user is learner or facilitator
        const resultData = response.data.data;
        const userId = resultData.user_info.id;
        const roles = resultData.roles;
        const isFacilitator = Object.values(roles).some(
          role => role.includes(parseInt(userId)) && role !== "Group Moderator"
        );
        commit("SET_IS_LEARNER", isFacilitator ? false : true);
        commit("SET_USER_NAME", response.data.data.user_info.username);
        return response.data.data;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        commit("SET_LIST_POST_LOADING", false);
      });
  },
  getListOfPosts({ commit, rootState }, params) {
    commit("SET_LIST_POST_LOADING", true);
    let URL = getURL(rootState.config.baseURL, params).GET_POST_LIST;
    if (params.text) {
      URL = URL + `&text=${params.text}`;
    }
    if (params.sort_key) {
      URL = URL + `&sort_key=${params.sort_key}&sort_order=desc`;
    }
    if (params.flagged) {
      URL = URL + `&flagged=true`;
    }
    if (params.unread) {
      URL = URL + `&unread=true`;
    }
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_LIST_POST", response.data.data);
        commit("SET_LIST_POST_LOADING", false);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        commit("SET_LIST_POST_LOADING", false);
      });
  },
  getListOfAllDraftPosts({ commit, rootState }, params) {
    commit("SET_DRAFT_LIST_POST_LOADING", true);
    let URL = getURL(rootState.config.baseURL, params).GET_ALL_DRAFT_POST_LIST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_DRAFT_LIST_POST", response.data.data);
        commit("SET_DRAFT_LIST_POST_LOADING", false);
        return response.data.data;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        commit("SET_DRAFT_LIST_POST_LOADING", false);
      });
  },
  getDraftListOfPosts({ commit, rootState }, params) {
    commit("SET_DRAFT_LIST_POST_LOADING", true);
    let URL = getURL(rootState.config.baseURL, params).GET_DRAFT_POST_LIST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_DRAFT_LIST_POST", response.data.data);
        commit("SET_DRAFT_LIST_POST_LOADING", false);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        commit("SET_DRAFT_LIST_POST_LOADING", false);
      });
  },
  getPostDetails({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_POST_DETAILS;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_SELECTED_DISCUSSION_POST", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },
  getDraftPostDetails({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_DRAFT_POST_DETAILS;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_SELECTED_DISCUSSION_POST", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getPostResponses({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_POST_RESPONSES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_POST_RESPONSES", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getPostResponseComments({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params)
      .GET_POST_RESPONSE_COMMENTS;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        return response.data.data;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getDiscussionTopics({ commit, rootState }, course_id) {
    return axios
      .get(`${rootState.config.baseURL}/discussions/${course_id}/topics`, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        commit("SET_DISCUSSION_TOPICS", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },
  getCourseUsersList({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_COURSE_USERS_LIST;

    return axios
      .get(URL, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        commit("SET_COURSE_USERS_LIST", response.data.data.results);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },

  // POST
  createPostResponse({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).CREATE_POST_RESPONSE;
    return axios
      .post(URL, params, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        return response.data.data;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },
  createResponseComment({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params)
      .CREATE_RESPONSE_COMMENT;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.post(URL, params, headers).catch(err => {
      commit("SET_ERRORS", err.response.data);
    });
  },
  createResponseVote({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).CREATE_RESPONSE_VOTE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .post(URL, params, headers)
      .then(response => {
        return response.data.data.vote_count;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  createPostVote({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).CREATE_POST_VOTE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .post(URL, params, headers)
      .then(response => {
        return response.data.data.vote_count;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  createCourseDiscussionPost({ commit, rootState }, postDetails) {
    return axios
      .post(`${rootState.config.baseURL}/discussions/posts`, postDetails, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        return response.data.data;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },
  reportCourseDiscussionPost(
    { commit, rootState },
    { courseId, reportId, reportOption }
  ) {
    const formData = new FormData();
    formData.append("report_option", reportOption);
    return axios
      .post(
        `${rootState.config.baseURL}/discussions/${courseId}/posts/${reportId}/flagabuse`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${this._vm.$keycloak.token}`
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },
  reportPostResponse(
    { commit, rootState },
    { courseId, reportId, reportOption }
  ) {
    const formData = new FormData();
    formData.append("report_option", reportOption);
    return axios
      .post(
        `${rootState.config.baseURL}/discussions/${courseId}/responses/${reportId}/flagabuse`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${this._vm.$keycloak.token}`
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },
  pinCourseDiscussionPost({ commit, rootState }, { courseId, postId }) {
    return axios
      .post(
        `${rootState.config.baseURL}/discussions/${courseId}/posts/${postId}/pin`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this._vm.$keycloak.token}`
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },
  unpinCourseDiscussionPost({ commit, rootState }, { courseId, postId }) {
    return axios
      .post(
        `${rootState.config.baseURL}/discussions/${courseId}/posts/${postId}/unpin`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this._vm.$keycloak.token}`
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },

  // PUT
  updateCourseDiscussionPost(
    { commit, rootState },
    { postId, postDetails, isDraft }
  ) {
    let url = `${rootState.config.baseURL}/discussions/posts/${postId}`;
    if (isDraft) {
      url = url + "?is_draft=true";
    }
    return axios
      .put(url, postDetails, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        return response.data.data;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },
  updatePostResponse({ commit, rootState }, { params, responseId }) {
    const URL = getURL(rootState.config.baseURL, { responseId })
      .UPDATE_POST_RESPONSE;
    return axios
      .put(URL, params, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        return response.data.data;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },

  // DELETE
  deleteResponseVote({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).CREATE_RESPONSE_VOTE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .delete(URL, headers, params)
      .then(response => {
        return response.data.data.vote_count;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  deletePostVote({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).DELETE_POST_VOTE;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .delete(URL, headers, params)
      .then(response => {
        return response.data.data.vote_count;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  deleteCourseDiscussionPost({ commit, rootState }, { postId, isDraft }) {
    let url = `${rootState.config.baseURL}/discussions/posts/${postId}`;
    if (isDraft) {
      url = url + "?is_draft=true";
    }
    return axios
      .delete(url, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        return response;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },
  deletePostResponse({ commit, rootState }, { responseId }) {
    const URL = getURL(rootState.config.baseURL, { responseId })
      .DELETE_POST_RESPONSE;
    return axios
      .delete(URL, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        return response;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  },

  //TOXIC LANGUAGE
  checkToxicLanguage({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).TOXIC_LANGUAGE_URL;
    return axios
      .post(URL, params)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
        throw err;
      });
  }
};
