export default {
  showFullPageLoader: false,
  error: "",
  courses: [],
  homeCourses: [],
  config: {},
  auth: {},
  authProfile: {},
  courseModules: [],
  selectedCourse: {},
  courseOverview: null,
  constants: null,
  new_user: null,
  welcome_modal_status: false,
  onboardingStatus: "",
  credentials: [],
  sessionCookie: [],
  languages: [],
  enrolledCourses: [],
  credential: {},
  language: "en",
  showCompleteProfile: false,
  showMaintenancePage: false,
  userProfile: {},
  serverTime: "",
  pageNotFound: false,

  // for layout
  expandSideNav: true,
  searchText: "",
  layout: "main",
  layoutUpdateTriggered: true,
  systemAlert: null,
  showSystemAlert: true,

  //for vue tour
  vueTourStatus: false,
  newVLEUser: null,

  //for discussion forums
  newForumsUser: null,

  //for header
  isHeaderFixed: false
};
