import axios from "axios";

axios.defaults.headers.common["Accept-Language"] = "en";

const getAuthHeader = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

function getListUrl(
  baseURL,
  state,
  includingSemanticResults = false,
  nSemanticResults = 4
) {
  let URL;
  if (state) {
    let urlPath = includingSemanticResults
      ? "/courses/discovery-paginated-semantic"
      : "/courses/discovery-paginated";
    URL = `${baseURL}${urlPath}?page=${state.pageNumber}&page_size=${state.pageSize}`;
    if (state.searchText !== "") {
      URL = URL + `&q=${state.searchText}`;
    }
    if (includingSemanticResults) {
      URL = URL + `&semantic_size=${nSemanticResults}`;
    }
    if (state.courseLanguage) {
      URL = URL + `&language=${state.courseLanguage}`;
    }
  }
  return URL;
}

export function getURL(baseURL, params = {}, includingSemanticResults = false) {
  return {
    GET_COURSE_LIST: getListUrl(baseURL, params, includingSemanticResults),
    GET_SEARCH_HISTORY_LIST: `${baseURL}/search-history`,
    POST_EMAIL: `${baseURL}/subscribes`,
    GET_SEARCH_LIST: `${baseURL}/courses/discovery-paginated?page=${params?.pageNumber}&page_size=${params?.pageSize}`,
    GET_COURSEWARE_LIST: `${baseURL}/courses/discovery-paginated-semantic?page=${params.pageNumber}&page_size=${params.pageSize}&semantic_size=${params.semanticSize}&language=${params.language}&filter_count=true`,
    GET_PARTNER_LIST: `${baseURL}/spaces/no-auth/live/?page_size=${params.pageSize}&page=${params.pageNumber}`,
    DELETE_SEARCH_HISTORY_LIST: `${baseURL}/search-history/${
      params ? params.id : ""
    }`,
    GET_PARTNER_DETAIL: `${baseURL}/spaces/no-auth/${params.org}/live/`,
    GET_FEATURED_RESOURCE: `${baseURL}/spaces/no-auth/${params.org}/courses/featured/live/`,
    GET_INITIATIVES: `${baseURL}/spaces/no-auth/${params.org}/initiatives/live/`,
    GET_SECTIONS: `${baseURL}/spaces/no-auth/${params.org}/sections/live/`,
    GTE_TAGS: `${baseURL}/tags/no-auth/live/?org=${params.org}`
  };
}

export default {
  getCourses({ commit, rootState }, includingSemanticResults = false) {
    return new Promise((resolve, reject) => {
      const URL = getURL(
        rootState.config.baseURL,
        rootState.courseDiscovery,
        includingSemanticResults
      ).GET_COURSE_LIST;
      axios
        .get(URL)
        .then(response => {
          commit("SET_COUNT", response.data.count);
          commit("GET_ALL_COURSES", {
            data: response.data,
            includingSemanticResults
          });
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // GET
  listSearchHistory({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).GET_SEARCH_HISTORY_LIST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    axios.get(URL, headers).then(response => {
      commit("SET_SEARCH_HISTORY_LIST", response.data.data);
    });
  },
  //New Header Search List
  getSearchList({ commit, rootState }, params) {
    let URL = getURL(rootState.config.baseURL, params).GET_SEARCH_LIST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    if (params.searchText !== "") {
      URL = URL + `&q=${params.searchText}`;
    }
    if (params.language) {
      URL = URL + `&language=${params.language}`;
    }
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_COUNT", response.data.count);
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  },
  //New Course List
  getCoursewareList({ rootState }, params) {
    let URL = getURL(rootState.config.baseURL, params).GET_COURSEWARE_LIST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    if (params.searchText !== "") {
      URL = URL + `&q=${params.searchText}`;
    }
    if (params.pacing) {
      URL = URL + `&pacing=${params.pacing}`;
    }
    if (params.sort_by) {
      URL = URL + `&sort_by=${params.sort_by}`;
    }

    return axios
      .get(URL, headers)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  },
  //Partners List
  getPartnersList({ rootState }, params) {
    let URL = getURL(rootState.config.baseURL, params).GET_PARTNER_LIST;
    return axios
      .get(URL)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  },

  getPartnersDetail({ rootState }, params) {
    let URL = getURL(rootState.config.baseURL, params).GET_PARTNER_DETAIL;
    return axios
      .get(URL)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  },

  getFeatureResource({ rootState }, params) {
    let URL = getURL(rootState.config.baseURL, params).GET_FEATURED_RESOURCE;
    return axios
      .get(URL)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  },
  getInitiativesInfo({ rootState }, params) {
    let URL = getURL(rootState.config.baseURL, params).GET_INITIATIVES;
    return axios
      .get(URL)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  },
  getSectionsInfo({ rootState }, params) {
    let URL = getURL(rootState.config.baseURL, params).GET_SECTIONS;
    return axios
      .get(URL)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  },
  getTagsInfo({ rootState }, params) {
    let URL = getURL(rootState.config.baseURL, params).GTE_TAGS;
    return axios
      .get(URL)
      .then(response => {
        return response.data;
      })
      .catch(err => {
        throw err;
      });
  },

  // POST
  saveAUserSearch({ rootState, dispatch }, search) {
    const URL = getURL(rootState.config.baseURL, search)
      .GET_SEARCH_HISTORY_LIST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    axios.post(URL, search, headers).then(() => {
      dispatch("listSearchHistory");
    });
  },
  subscribeEmail({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).POST_EMAIL;
    return axios
      .post(URL, params)
      .then(response => {
        return response;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response);
        throw err;
      });
  },

  // DELETE
  deleteASearchHistory({ commit, rootState, dispatch }, history) {
    const URL = getURL(rootState.config.baseURL, history)
      .DELETE_SEARCH_HISTORY_LIST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    axios.delete(URL, headers).then(() => {
      commit("UPDATE_SEARCH_HISTORY", history);
      dispatch("listSearchHistory");
    });
  }
};
