<template>
  <div class="vle-accordian-old">
    <div class="lx-collapse">
      <div class="lx-collapse__title c-pointer" @click="toggleCollapse">
        <b-row class="m-0">
          <b-col cols="10 p-0">
            <slot name="accordion-title"></slot>
          </b-col>
          <b-col cols="2" class="d-flex justify-content-center">
            <Icon
              :iconName="visible ? 'chevron-up' : 'chevron-down'"
              variant="grey"
            />
          </b-col>
          <div
            :class="{ 'lx-collapse__open': open, 'lx-collapse__body': true }"
            @click.prevent="toggleCollapse"
          >
            <!-- TODO make it as a accordion and one open at a time -->
            <slot name="accordion-body"></slot>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "./Icon.vue";
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Icon
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    open(newVal) {
      if (newVal !== this.visible) {
        this.updateVisible(newVal);
      }
    }
  },
  created() {
    this.updateVisible(this.open);
  },
  methods: {
    toggleCollapse() {
      this.visible = !this.visible;
      this.$emit("toggle", this.visible);
    },
    updateVisible(bool) {
      this.visible = bool;
    }
  }
};
</script>
<style lang="scss">
[dir="rtl"] {
  .vle-accordian-old {
    .lx-collapse {
      .lx-collapse__title {
        text-align: right;

        .lx-collapse__toggle {
          right: auto;
          left: 0;
        }
      }

      .lx-collapse__body {
        padding: 0;
      }
    }
  }
}

.vle-accordian-old {
  .lx-collapse {
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 0 10px;

    .lx-collapse__title {
      padding: 15px 0;

      .lx-collapse__toggle {
        cursor: pointer;
        position: absolute;
        right: 0;
        height: 17px;
        width: 17px;
        font-size: 17px;
        line-height: 17px;
        background: $brand-primary;
        color: #fff;
        text-align: center;
        border-radius: 100%;
        font-weight: 700;
        transition: transform 0.3s;

        &.active {
          transform: rotate(45deg);
        }
      }

      .b-icon {
        font-size: 20px;
        color: $brand-public-disabled;
      }
    }

    .lx-collapse__body {
      opacity: 0;
      height: 0;
      padding: 0;
      transition: opacity 0.15s ease;

      &.lx-collapse__open {
        height: auto;
        opacity: 1;
        width: 100%;
      }
    }
  }
}
</style>
