<template>
  <div class="lx-teams-collapse">
    <div
      class="lx-teams-collapse__title d-flex flex-row"
      @click="toggleCollapse"
    >
      <div
        class="topic-icon text-center"
        :style="`background:${this.themeColor}`"
      >
        <img
          src="@/assets/images/icons/teams-topic-icon.svg"
          alt="topic-icon"
          class="mt-2"
          width="25"
          height="25"
        />
      </div>
      <div class="topic-title flex-grow-1" :title="title">
        {{ title }}
      </div>
      <div>
        <b-badge variant="light" class="teams-count-badge">{{
          teamCount
        }}</b-badge>
      </div>
      <div class="lx-teams-collapse__toggle" :class="{ active: visible }">
        <img
          src="@/assets/images/icons/arrow-down-blue.svg"
          alt="arrow-down-blue-icon"
          class="mt-2"
          width="12"
          height="auto"
        />
      </div>
    </div>
    <div class="lx-collapse__body">
      <b-collapse v-model="visible" :accordion="accordion">
        <slot>Collapse Body</slot>
      </b-collapse>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    themeColor: {
      type: String,
      default: "#e1ddff"
    },
    title: {
      type: String,
      default:
        "Collapse Title Collapse Title Collapse Title Collapse Title Collapse Title Collapse Title Collapse Title Collapse Title Collapse Title Collapse Title Collapse Title Collapse Title"
    },
    teamCount: {
      type: String,
      default: "20 teams"
    },
    open: {
      type: Boolean,
      default: false
    },
    accordion: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    open(newVal) {
      if (newVal !== this.visible) {
        this.updateVisible(newVal);
      }
    }
  },
  created() {
    this.updateVisible(this.open);
  },
  methods: {
    toggleCollapse() {
      this.$emit("touched");
      this.visible = !this.visible;
    },
    updateVisible(bool) {
      this.visible = bool;
    }
  }
};
</script>
<style lang="scss">
$default-size: 40px;

.lx-teams-collapse {
  border-bottom: 1px solid #acacac;
  padding: 20px;

  .lx-teams-collapse__title {
    text-align: left;
    cursor: pointer;
    width: 100%;
  }
  .topic-icon {
    min-width: $default-size;
    height: $default-size;
    background: #e1ddff;
    border-radius: 8px;
  }
  .topic-title {
    color: #191c1d;
    font-weight: 400;
    font-size: 16px;
    margin: 0 16px;
    line-height: $default-size;

    @include text-ellipsis;
  }

  .lx-teams-collapse__toggle {
    min-width: 12px;
  }

  .teams-count-badge {
    border: 1px solid #acacac;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #ffffff;
    font-family: $font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 3px 10px;
    margin: 6px 30px 0 0;
    color: #44464e;
  }
}
// rtl layout styles
[dir="rtl"] {
  .lx-teams-collapse {
    .topic-title {
      text-align: right;
    }
    .teams-count-badge {
      margin: 6px 0px 0 30px;
    }
  }
}
</style>
