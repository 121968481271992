export default () => ({
  currentStatus: "INITIAL",
  profile: {},
  form: {
    given_name: "",
    family_name: "",
    display_name: "",
    gender: "",
    email: "",
    country: "",
    city: "",
    age: "",
    role: "",
    role_tenure: "",
    interests: "",
    udf_role: "",
    udf_gender: "",
    language: "",
    affiliation: "",
    date_of_birth: "",
    education: ""
  },
  sections: [
    {
      id: "general",
      isEdit: false
    },
    {
      id: "professional_info",
      isEdit: false
    },
    {
      id: "learning_interest",
      isEdit: false
    },
    {
      id: "system_language",
      isEdit: false
    }
  ],
  settingsCurrentSection: null
});
