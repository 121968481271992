export default {
  getTopicsList: state => state.topicsList,
  getTopicDetail: state => state.topicDetail,
  getMyTeamsList: state => state.myTeamsList,
  getTeamsList: state => state.teamsList,
  getTeamsViewSelector: state => state.teamsViewSelector,
  getTeamDetail: state => state.teamDetail,
  restrictJoinTeams: state => state.restrictJoinTeams,
  getTeamsDiscussionList: state => state.discussionList
};
