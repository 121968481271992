import { render, staticRenderFns } from "./CourseCardLanding.vue?vue&type=template&id=0640d8bb&scoped=true"
import script from "./CourseCardLanding.vue?vue&type=script&lang=js"
export * from "./CourseCardLanding.vue?vue&type=script&lang=js"
import style0 from "./CourseCardLanding.vue?vue&type=style&index=0&id=0640d8bb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0640d8bb",
  null
  
)

export default component.exports