<template>
  <div class="lx-input" :class="className">
    <div :class="{ error: showError, 'lx-input__div': true, 'pre-ob': preOb }">
      <b-form-group id="input-group-1" :label="label" label-for="input-1">
        <b-input-group v-if="isAppend || isPrepend">
          <template #append v-if="isAppend">
            <div v-html="append" class="d-inline-block" />
          </template>
          <template #prepend v-if="isPrepend">
            {{ prepend }}
          </template>
          <b-form-input
            :id="id"
            :type="type"
            :required="required"
            :placeholder="placeholder"
            @input="updateValue($event)"
            :value="value"
            :disabled="disabled"
            :state="state"
          ></b-form-input>
        </b-input-group>
        <b-form-input
          v-else
          :id="id"
          :type="type"
          :required="required"
          :placeholder="placeholder"
          @input="updateValue($event)"
          :value="value"
          :disabled="disabled"
          :state="state"
        ></b-form-input>
      </b-form-group>
    </div>
    <p class="error-message" v-if="showError">{{ errorMessage }}</p>
  </div>
</template>
<script>
export default {
  name: "lx-input",
  props: {
    id: String,
    label: String,
    type: {
      type: String,
      default: "text"
    },
    placeholder: String,
    showError: Boolean,
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: "*This is a mandatory field"
    },
    value: String,
    isAppend: {
      type: Boolean,
      default: false
    },
    isPrepend: {
      type: Boolean,
      default: false
    },
    append: String,
    prepend: String,
    disabled: {
      type: Boolean,
      default: false
    },
    preOb: {
      type: Boolean,
      default: false
    },
    className: String,
    state: {
      type: Boolean,
      default: null
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    }
  }
};
</script>
<style lang="scss">
[dir="rtl"] {
  .lx-input {
    .form-group {
      label {
        text-align: right;
      }
    }
    .error-message {
      text-align: right;
    }
  }
}
.lx-input {
  margin-bottom: 20px;
  .lx-input__div {
    & &.error {
      border: 1px solid #000000;
    }
    &.pre-ob {
      background: #f9f9fa;
      border-radius: 4px 4px 0px 0px;
      padding: 9px 12px;
      border-bottom: 1px solid #cacccf;

      .form-group {
        label {
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.4px;
          color: #52575c;
          margin-bottom: 0;
        }
        input {
          background-color: transparent;
          border: none;
          font-size: 16px;
          border-radius: 0;
          padding: 9px 0 0;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #25282b;
          &:focus {
            border: none;
          }
          &::placeholder {
            font-size: 16px;
          }
        }
      }
    }
    .form-group {
      margin-bottom: 0px;
      label {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
      }
      input {
        padding: 9px 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #25282b;
        border: 1px solid #eee;
        border-radius: 4px;
        &:focus {
          box-shadow: none;
          border: 1px solid #0043ac;
        }
        &::placeholder {
          color: rgba(0, 0, 0, 0.6);
          font-size: 12px;
          letter-spacing: 0.2px;
        }
        &:disabled {
          background: #f9f9fa;
          border: 1px solid #eeeeee;
        }
      }
      .input-group {
        .input-group-append {
          position: absolute;
          right: 10px;
          top: 5px;
          a {
            font-weight: 600;
            font-size: 12px;
            line-height: 140%;
            text-align: right;
            color: #0043ac;
            cursor: pointer;
          }
        }
        .input-group-prepend {
          position: absolute;
          left: 10px;
          top: 5px;
        }
      }
    }
  }
  .error-message {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #f44336;
    margin-top: 5px;
  }
}
</style>
