<template>
  <div class="w-100">
    <div class="lx-progress" :class="[type, dynamicColor]">
      <b-progress :value="value" :max="max"> </b-progress>
    </div>
    <div class="text-right" v-if="showPrgressValue">{{ value }}%</div>
  </div>
</template>
<script>
/*
Inspired by: https://bootstrap-vue.org/docs/components/progress#progress

TODO:: Add validation for props
*/
export default {
  props: {
    value: {
      type: Number,
      default: 25
    },
    max: {
      type: Number,
      default: 100
    },
    type: {
      type: String,
      default: "",
      validator: value => {
        // The value must match one of these strings
        return ["", "slim"].indexOf(value) !== -1;
      }
    },
    bg: {
      // will be used along with type: slim
      type: String,
      default: "bg-1",
      validator: value => {
        // The value must match one of these strings
        return ["bg-1", "bg-2", "bg-3", "bg-4"].indexOf(value) !== -1;
      }
    },
    showPrgressValue: {
      type: Boolean
    }
  },
  computed: {
    dynamicColor() {
      if (parseFloat(parseInt(this.value)) === 100) {
        return "bg-1";
      } else if (parseFloat(parseInt(this.value)) > 74) {
        return "bg-4";
      } else if (parseFloat(parseInt(this.value)) > 49) {
        return "bg-3";
      } else {
        return "bg-2";
      }
    }
  }
};
</script>
<style lang="scss">
$bg-1-foreground-color: #00c781;
$bg-1-background-color: #00c781;

$bg-2-foreground-color: $brand-primary-400;
$bg-2-background-color: #adffa6;

$bg-3-foreground-color: $brand-secondary1-400;
$bg-3-background-color: #e3deff;

$bg-4-foreground-color: #f8b400;
$bg-4-background-color: #030302;

.lx-progress {
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  padding: 4px 5px;

  .progress {
    height: 4px;
    background-color: #e8e8e8;
    border-radius: 6px;

    .progress-bar {
      height: 4px;
      background-color: $brand-primary-darker;
    }
  }

  &.slim {
    border: none;
    padding: 0;
    .progress {
      border: none;
    }

    // TODO::refactor to use mixins

    &.bg-1 {
      .progress {
        .progress-bar {
          background-color: $bg-1-foreground-color;
        }
      }
    }

    &.bg-2 {
      .progress {
        .progress-bar {
          background-color: $bg-2-foreground-color;
        }
      }
    }

    &.bg-3 {
      .progress {
        .progress-bar {
          background-color: $bg-3-foreground-color;
        }
      }
    }

    &.bg-4 {
      .progress {
        .progress-bar {
          background-color: $bg-4-foreground-color;
        }
      }
    }
  }
}
</style>
