<template>
  <div class="landing-course-card" :title="courseTitle" @click="$emit('click')">
    <b-card :img-src="courseImage" img-alt="course image" img-top>
      <div>
        <div class="landing-course-card__title">{{ courseTitle }}</div>
        <div class="landing-course-card__footer d-flex justify-content-between">
          <div
            class="landing-course-card__pacing p-0"
            :class="pacing"
            :title="$t(`data.pacing.${pacing}`)"
          >
            <img
              src="@/assets/images/wrapper-components/course-card/self-paced-icon.svg"
              alt="pacing"
              v-if="pacing === 'self'"
            />
            <img
              src="@/assets/images/wrapper-components/course-card/instructor-led-icon.svg"
              alt="pacing"
              v-else
            />
            <template v-if="showEllipses">{{
              $t(`data.pacing.${pacing}`) | truncate(9, "...")
            }}</template>
            <template v-else>{{ $t(`data.pacing.${pacing}`) }}</template>
          </div>
          <div class="d-flex p-0">
            <img
              src="@/assets/images/wrapper-components/course-card/language-icon.svg"
              alt="language"
            />
            {{ $t(`data.language.${courseLanguage}`) }}
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
export default {
  computed: {
    showEllipses() {
      if (
        this.language === "es" ||
        this.language === "ru" ||
        this.language === "fr"
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  props: {
    courseImage: {
      type: String,
      default: require("@/assets/images/illustration/course-illustration.svg")
    },
    org: {
      type: String
    },
    courseTitle: {
      type: String,
      required: true
    },
    pacing: {
      type: String,
      required: true,
      validator: value => {
        // The value must match one of these strings
        return ["self", "instructor"].indexOf(value) !== -1;
      }
    },
    courseLanguage: {
      type: String,
      required: true,
      default: "en",
      validator: value => {
        // The value must match one of these strings
        return (
          [
            "ar",
            "zh",
            "en",
            "fr",
            "ru",
            "es",
            "bs",
            "cs",
            "et",
            "fil",
            "hr",
            "hy",
            "it",
            "lt",
            "pl",
            "pt",
            "ro",
            "sl",
            "tr",
            "uk"
          ].indexOf(value) !== -1
        );
      }
    },
    language: {
      type: String,
      default: "en"
    }
  }
};
</script>
<style lang="scss" scoped>
[dir="rtl"] {
  .card {
    text-align: right;
    .landing-course-card__footer {
      img {
        margin-left: 5px;
        margin-right: unset;
      }
    }
  }
}
.landing-course-card {
  .card-img-top {
    height: 92px !important;
  }
  .card {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    border: none;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.3s;
    background: #fbfdfd;
    max-width: 592px;
    margin-top: 13px;

    .card-img,
    .card-img-top {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      object-fit: fill;
    }

    .card-body {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 14px 12px;

      .landing-course-card__title {
        color: #44464e;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: initial;
        word-break: break-all;
        min-height: 60px;
        font-family: $font-family;
        @include line-clamp(2);
      }

      .landing-course-card__footer {
        font-size: 12px;
        line-height: 20px;
        color: #757575;
        font-weight: 700;
        height: auto;
        .landing-course-card__pacing {
          color: #e71f97;
          &.instructor {
            color: #e71f97;
            text-align: initial;
          }
          &.self {
            color: #00bfa4;
            text-align: initial;
          }
        }

        img {
          margin-right: 3px;
          vertical-align: middle;
          height: 20px;
        }
      }
    }

    &:hover {
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
      transform: scale(1.02);
    }
  }
}
@media screen and (max-width: 769px) {
  .landing-course-card__footer {
    height: 20px !important;
  }
  .landing-course-card__pacing {
    &.instructor {
      width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

@media (min-width: 767px) and (max-width: 886px) {
  .landing-course-card__pacing {
    &.instructor {
      width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &.self {
      width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
