<template>
  <div
    :class="[
      'lx-course-card__body',
      `lx-course-card__${status?.toLowerCase()}`
    ]"
  >
    <div class="lx-course-card__content">
      <div class="lx-course-card__org" @click="$emit('orgClick')">
        {{ org }}
      </div>
      <div class="lx-course-card__title">{{ courseTitle }}</div>

      <div class="lx-course-card__footer">
        <div
          class="lx-course-card__footer--div lx-card__footer--enroll"
          v-if="status === 'TO_ENROLL'"
        >
          <div class="lx-course-card__pacing" :class="pacing">
            <img
              src="@/assets/images/wrapper-components/course-card/self-paced-icon.svg"
              alt="pacing"
              v-if="pacing === 'self'"
            />
            <img
              src="@/assets/images/wrapper-components/course-card/instructor-led-icon.svg"
              alt="pacing"
              v-else
            />

            {{ $t(`data.pacing.${pacing}`) }}
          </div>
          <div class="lx-course-card__language">
            <img
              src="@/assets/images/layout/user-fluid-layout/language-grey.svg"
              alt="language"
            />
            {{ courseLanguage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "org",
    "courseTitle",
    "pacing",
    "courseLanguage",
    "course",
    "status",
    "currentServerTime"
  ]
};
</script>
<style lang="scss">
@import "@/scss/wrapper-components/_course-card.scss";
.lx-course-card__body {
  padding: 0 16px;
  .lx-course-card__content {
    .lx-course-card__title {
      height: auto;
    }
  }
}
</style>
