/* eslint-disable no-unused-vars */
import axios from "axios";
import i18n from "../../../i18next";

axios.defaults.headers.common["Accept-Language"] = "en";

const getAuthHeader = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

const getAuthHeaderV2WithCookie = (token, cookie) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json;version=2.0",
      Openedxcookie: JSON.stringify(cookie)
    }
  };
};

function getURL(baseURL, params) {
  return {
    // POST
    CREATE_CREDENTIAL: `${baseURL}/digital-credentials/generate-badge/${params.courseId}/${params.sectionId}`,
    UNENROLL: `${baseURL}/courses/${params}/unenroll`,
    // PUT
    UPDATE_POSITION: `${baseURL}/courses/${params.courseId}/${params.sequenceId}/position`
  };
}

export default {
  // POST
  createCredential({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).CREATE_CREDENTIAL;
    const headers = getAuthHeaderV2WithCookie(
      this._vm.$keycloak.token,
      rootState.sessionCookie
    );
    return new Promise((resolve, reject) => {
      axios
        .post(URL, {}, headers)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          // Create error object
          //   e.g. {
          //     'status': status,
          //     'title': title,
          //     'detail': detail,
          //     'instance': req.url
          // }
          let error = {};
          error.status = err.response.status;
          error.title = i18n.t("vle.assessment.timed.modal.error.title");
          error.detail = i18n.t("vle.assessment.timed.modal.error.content");
          error.instance = URL;
          commit("SET_ERRORS", error);

          reject(error);
        });
    });
  },

  // PUT
  updateUnitPosition({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).UPDATE_POSITION;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .put(URL, { position: params.position }, headers)
      .then(response => {})
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  unenroll({ commit, rootState }, data) {
    const URL = getURL(rootState.config.baseURL, data.course_id).UNENROLL;
    const headers = getAuthHeaderV2WithCookie(
      this._vm.$keycloak.token,
      rootState.sessionCookie
    );
    return new Promise((resolve, reject) => {
      axios
        .post(URL, {}, headers)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          commit("SET_ERRORS", err.response.data);
          reject(err.response.data);
        });
    });
  },
  getVLEUserStatus({ commit, rootState }) {
    return axios
      .get(`${rootState.config.baseURL}/vle-new-user`, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        commit("SET_VLE_USER_STATUS", response.data["is_vle_new_user"]);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getForumsUserStatus({ commit, rootState }) {
    return axios
      .get(`${rootState.config.baseURL}/discussions/firstvisit`, {
        headers: {
          Authorization: `Bearer ${this._vm.$keycloak.token}`
        }
      })
      .then(response => {
        commit("SET_FORUMS_USER_STATUS", response.status);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  }
};
