// TODO: Refactor this logic
export default state => {
  if (state.showDiscussionTopics.length === 0 || state.topicId === "") {
    return [];
  }
  let breadcrumbs = [];

  // category
  let index = state.showDiscussionTopics.findIndex(d => d.id === state.topicId);

  // found in category
  if (index !== -1) {
    breadcrumbs = [
      {
        id: state.showDiscussionTopics[index].id,
        name: state.showDiscussionTopics[index].name
      }
    ];
  } else {
    // if topicId has commas(,)
    if (state.topicId.indexOf(",") !== -1) {
      for (let i = 0; i < state.showDiscussionTopics.length; i++) {
        if (state.showDiscussionTopics[i].id === null) {
          let id = this.showDiscussionTopics[i].categories
            .map(x => x.id)
            .join(",");

          if (id === state.topicId) {
            breadcrumbs = [
              {
                id: state.showDiscussionTopics[i].id,
                name: state.showDiscussionTopics[i].name
              }
            ];
          }
        }
      }
    } else {
      // sub category
      for (let i = 0; i < state.showDiscussionTopics.length; i++) {
        for (
          let j = 0;
          j < state.showDiscussionTopics[i].categories.length;
          j++
        ) {
          if (
            state.showDiscussionTopics[i].categories[j].id === state.topicId
          ) {
            breadcrumbs = [
              {
                id: state.showDiscussionTopics[i].id,
                name: state.showDiscussionTopics[i].name
              },
              {
                id: state.showDiscussionTopics[i].categories[j].id,
                name: state.showDiscussionTopics[i].categories[j].name
              }
            ];
          }
        }
      }
    }
  }

  return breadcrumbs;
};
