<template>
  <div class="pagination__wrapper">
    <empty-state v-if="showEmptyState" />
    <div class="d-flex flex-wrap" v-else>
      <slot name="course-card" />
      <slot name="card-loader" />
      <div class="w-100 text-center">
        <a class="ac__load-more" @click="$emit('onLoadMore')" v-if="isLoadMore">
          {{ $t(viewAllText) }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import EmptyState from "@/components/SearchCourse/EmptyState.vue";
export default {
  props: {
    isLoadMore: {
      type: Boolean,
      default: false
    },
    showEmptyState: {
      type: Boolean,
      default: false
    },
    viewAllText: {
      type: String,
      default: "general.view_all"
    }
  },
  components: {
    EmptyState
  }
};
</script>
<style lang="scss" scoped>
.pagination__wrapper .ac__load-more {
  @include button-label;
  color: $brand-primary;
}
</style>
