<template>
  <b-container class="p-0 rc__wrapper">
    <div class="rc__wrapper--title d-flex mb-3">
      <img
        :src="require(`@/assets/logo/related-courses.svg`)"
        alt="Courses"
        width="auto"
        height="auto"
      />
      <h4 class="rc-heading pt-2">{{ heading }}</h4>
    </div>
    <b-row class="rc__desktop">
      <b-col
        md="3"
        class="rc__card pr-0"
        v-for="(course, index) in relatedCourses"
        :key="index"
      >
        <CourseCard
          :courseImage="
            course.image_url
              ? course.image_url
              : require('@/assets/images/illustration/course-illustration.svg')
          "
          :org="course.org"
          :language="language"
          :courseTitle="course.title"
          :pacing="course.pacing"
          :courseLanguage="course.language"
          :course="course"
          @click="onCard(course)"
        />
      </b-col>
    </b-row>
    <!-- in mobile case -->
    <b-row class="rc__mobile slider">
      <b-col md="3" v-for="(course, index) in relatedCourses" :key="index">
        <CourseCard
          :courseImage="
            course.image_url
              ? course.image_url
              : require('@/assets/images/illustration/course-illustration.svg')
          "
          :org="course.org"
          :language="language"
          :courseTitle="course.title"
          :pacing="course.pacing"
          :courseLanguage="course.language"
          :course="course"
          @click="onCard(course)"
        />
      </b-col>
    </b-row>
    <b-row v-if="isLoading">
      <b-col md="3" class="mb-3 pr-0" v-for="c in [1, 2, 3, 4]" :key="c">
        <shimmer variant="landing-course-card" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { tns } from "tiny-slider/src/tiny-slider";
import { mapGetters } from "vuex";
export default {
  props: {
    heading: String
  },
  computed: {
    ...mapGetters(["language"])
  },
  data() {
    return {
      isLoading: false,
      sliderItems: 1,
      slider: null,
      relatedCourses: []
    };
  },
  created() {
    this.getCourses();
  },
  methods: {
    onCard(c) {
      this.$router.push({
        name: "Courses Detail",
        params: { id: c.id },
        query: { source: c.source }
      });
      document.getElementById("app").scrollIntoView();
    },
    async getCourses() {
      this.isLoading = true;
      this.$store.dispatch("getRandomCourses", 4).then(data => {
        this.relatedCourses = data;
        this.isLoading = false;
        setTimeout(() => {
          this.initiateSlider();
        }, 50);
      });
    },
    initiateSlider() {
      if (this.slider) {
        this.slider.destroy();
      }
      this.slider = tns({
        container: ".slider",
        items: this.sliderItems,
        slideBy: "page",
        autoplay: false,
        controls: false,
        loop: false,
        nav: false
      });
      this.getSliderInfo();
    },
    slideTo(action) {
      this.slider.goTo(action);
      this.getSliderInfo();
    },
    getSliderInfo() {
      let slider_info;
      if (this.slider) {
        slider_info = this.slider.getInfo();
      }
      this.currentSlide = slider_info ? slider_info.index : 0;
    }
  }
};
</script>
<style lang="scss">
.rc__wrapper {
  .rc__wrapper--title {
    text-align: left;
    .rc-heading {
      font-family: $font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      color: $brand-public-primary;
      padding-left: 0.5rem;
    }
  }
  .rc__mobile {
    display: none;
  }
  @media screen and (max-width: 500px) {
    .landing-course-card {
      margin-bottom: 20px;
    }
    .rc__mobile {
      display: block;
    }
    .rc__desktop {
      display: none;
    }
    .slider .tns-item {
      width: 16% !important;
    }
    .tns-ovh {
      text-align: initial;
      margin-right: -15px;
    }
  }
  @media (min-width: 500px) and (max-width: 768px) {
    .rc__card {
      width: 50%;
    }
    .landing-course-card {
      margin-bottom: 20px;
    }
  }
}
// rtl layout
[dir="rtl"] {
  .rc__wrapper {
    .rc__wrapper--title {
      text-align: right;
      .rc-heading {
        padding: 0 0.5rem 0 0;
      }
    }
  }
}
</style>
