/* *
 * COURSE_VIEW_SELECTOR: possible values
 * 1 - Landing Page
 * 2 - Course View Layout
 * 3 - Timed Assessment Completed Layout
 */

export default () => ({
  courseViewSelector: 2,
  isTimedAssessment: false,
  isTimedAssessmentSubmitted: false,
  timedData: {},
  showHeaderNav: true
});
