<template>
  <div class="w-100">
    <video ref="videoPlayer" class="video-js" v-if="type == 'video'"></video>
    <audio ref="audioPlayer" class="video-js" v-else></audio>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    type: {
      type: String,
      default: "video"
    }
  },
  data() {
    return {
      player: null
    };
  },
  mounted() {
    let player;
    this.type === "video"
      ? (player = this.$refs.videoPlayer)
      : (player = this.$refs.audioPlayer);
    this.player = videojs(player, this.options, function onPlayerReady() {});
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
};
</script>
<style lang="scss">
.video-js {
  width: 100%;
  height: 450px;
}
</style>
