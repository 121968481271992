<template>
  <div class="vle-accordian">
    <div class="lx-collapse">
      <div class="lx-collapse__title c-pointer" @click="toggleCollapse">
        <div class="d-flex justify-content-between">
          <div>
            <slot name="accordion-title"></slot>
            <slot name="accordion-sub-title"></slot>
          </div>
          <div>
            <img :src="visible ? chevronUp : chevronDown" alt="arrow" />
          </div>
        </div>
        <div>
          <slot name="accordion-complete-collapse-icon"></slot>
        </div>
        <b-collapse id="collapse-4" v-model="visible">
          <div
            :class="{ 'lx-collapse__open': open, 'lx-collapse__body': true }"
            @click.prevent="toggleCollapse"
          >
            <slot name="accordion-body"></slot>
            <slot name="accordion-complete-no-collapse-icon"></slot>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    module: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      chevronUp: require("@/assets/images/vle/chevron-up.svg"),
      chevronDown: require("@/assets/images/vle/chevron-down.svg")
    };
  },
  watch: {
    open(newVal) {
      if (newVal !== this.visible) {
        this.updateVisible(newVal);
      }
    }
  },
  created() {
    this.updateVisible(this.open);
  },
  methods: {
    toggleCollapse() {
      this.visible = !this.visible;
      this.$emit("toggle", this.visible);
    },
    updateVisible(bool) {
      this.visible = bool;
    }
  }
};
</script>
<style lang="scss">
[dir="rtl"] {
  .vle-accordian {
    .lx-collapse {
      .lx-collapse__title {
        text-align: right;

        .lx-collapse__toggle {
          right: auto;
          left: 0;
        }
      }

      .lx-collapse__body {
        padding: 0;
      }
    }
  }
}

.vle-accordian {
  .lx-collapse {
    background: $brand-neutral-0;
    border-radius: 8px;
    padding: 16px 16px;
    border-color: $brand-neutral-200;

    .lx-collapse__title {
      padding: 0;

      .lx-collapse__toggle {
        cursor: pointer;
        position: absolute;
        right: 0;
        height: 17px;
        width: 17px;
        font-size: 17px;
        line-height: 17px;
        background: $brand-primary;
        color: $brand-neutral-0;
        text-align: center;
        border-radius: 100%;
        font-weight: 700;
        transition: transform 0.3s;

        &.active {
          transform: rotate(45deg);
        }
      }

      .b-icon {
        font-size: 14px;
        color: $brand-default-text;
      }
    }

    .lx-collapse__body {
      opacity: 0;
      height: 0;
      padding: 0;
      transition: opacity 0.15s ease;

      &.lx-collapse__open {
        height: auto;
        opacity: 1;
        width: 100%;
      }
    }
  }
}
[dir="rtl"] {
  .lx-collapse {
    .lx-collapse__title {
      text-align: right;
      .lx-collapse__toggle {
        right: auto;
        left: 0;
      }
      .lx-section-completed {
        padding-right: 1rem;
        font-size: 10px;
      }
    }
    .lx-collapse__body {
      padding: 0;
    }
  }
}
</style>
