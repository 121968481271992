<template>
  <b-form-radio-group
    v-model="selected"
    :options="options"
    name="radio-options"
    class="lxp-radio-group"
    @input="onInput"
  ></b-form-radio-group>
</template>
<script>
export default {
  props: {
    options: Array,
    value: String
  },
  data() {
    return {
      selected: ""
    };
  },
  watch: {
    value(v) {
      this.selected = v;
    }
  },
  mounted() {
    this.selected = this.value;
  },
  methods: {
    onInput() {
      this.$emit("input", this.selected);
    }
  }
};
</script>
<style lang="scss">
.lxp-radio-group {
  .custom-control {
    align-items: flex-end;
    .custom-control-input {
      &:checked {
        ~ .custom-control-label {
          &::after {
            background: #0043ac;
            width: 7px;
            height: 7px;
            left: -21px;
            top: 7px;
            border-radius: 100%;
          }
          &::before {
            background-color: #fff;
            border-color: #0043ac;
          }
        }
      }
    }
    .custom-control-label {
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: #757575;
      &::before {
        background: #ffffff;
        border: 1.5px solid #757575;
        box-shadow: none !important;
      }
      &::before,
      &::after {
        width: 13px;
        height: 13px;
      }
    }
  }
}
</style>
