export default {
  allCourses: state => state.courses,
  allSemanticallySimilarCourses: state => state.semanticallySimilarCourses,
  getPageSize: state => state.pageSize,
  getPageNumber: state => state.pageNumber,
  isLoadMore: state => state.isLoadMore,
  getCourseLanguage: state => state.courseLanguage,
  getCount: state => state.count,
  getSearchText: state => state.searchText,
  getSearchHistory: state => state.searchHistory,
  getSelectedMenu: state => state.selectedMenu
};
