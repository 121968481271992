import axios from "axios";
import store from "../store";
// #https://github.com/axios/axios#interceptors
// use of axios interceptors to track the status code of api
let timer = null;
export default function interceptorSetup() {
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      //checking for maintenance
      if (
        error.response.status === 503 &&
        error.response.headers["retry-after"]
      ) {
        // Make sure there are no active timers
        if (timer === null) {
          const timerDuration =
            parseInt(error.response.headers["retry-after"]) * 1000;
          store.commit("SET_SHOW_MAINTENANCE_FLAG", true);
          timer = setTimeout(function() {
            store.dispatch("getMaintenanceStatus");
            clearTimeout(timer);
            timer = null;
          }, timerDuration);
        }
      } else {
        store.commit("SET_SHOW_MAINTENANCE_FLAG", false);
      }
      return Promise.reject(error);
    }
  );
}
