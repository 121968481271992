<template>
  <div class="lx-collapse">
    <div class="lx-collapse__title">
      <Milestone v-if="titleBullet === 'primary'"></Milestone>
      {{ title }}
      <span
        class="lx-collapse__toggle"
        :class="{ active: visible }"
        @click="toggleCollapse"
        >+</span
      >
    </div>
    <div class="lx-collapse__body">
      <b-collapse v-model="visible">
        <slot>Collapse Body</slot>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import Milestone from "./Milestone.vue";
export default {
  props: {
    title: {
      type: String,
      default: "Collapse Title"
    },
    titleBullet: {
      type: String,
      default: "none",
      validator: value => {
        // The value must match one of these strings
        return ["none", "primary"].indexOf(value) !== -1;
      }
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Milestone
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    open(newVal) {
      if (newVal !== this.visible) {
        this.updateVisible(newVal);
      }
    }
  },
  created() {
    this.updateVisible(this.open);
  },
  methods: {
    toggleCollapse() {
      this.visible = !this.visible;
    },
    updateVisible(bool) {
      this.visible = bool;
    }
  }
};
</script>
<style lang="scss">
[dir="rtl"] {
  .lx-collapse {
    .lx-collapse__title {
      text-align: right;
      .lx-collapse__toggle {
        right: auto;
        left: 0;
      }
    }
    .lx-collapse__body {
      padding: 15px 30px 0 0;
    }
  }
}
.lx-collapse {
  background: #fff;
  border: 1px solid $brand-grey-bg;
  border-radius: 4px;
  padding: 20px 20px 5px 30px;

  .lx-collapse__title {
    position: relative;
    padding-right: 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #25282b;

    .lx-collapse__toggle {
      cursor: pointer;
      position: absolute;
      right: 0;
      height: 17px;
      width: 17px;
      font-size: 17px;
      line-height: 17px;
      background: $brand-primary;
      color: #fff;
      text-align: center;
      border-radius: 100%;
      font-weight: 700;
      transition: transform 0.3s;

      &.active {
        transform: rotate(45deg);
      }
    }
  }

  .lx-collapse__body {
    padding-top: 15px;
    padding-left: 30px;
  }
}
</style>
