import breadcrumbs from "./breadcrumbs";
export default {
  showDiscussionTopics: state => state.showDiscussionTopics,
  getDiscussionViewSelector: state => state.discussionViewSelector,
  getTopicId: state => state.topicId,
  getDiscussionBreadcrumb: state => breadcrumbs(state),
  getDiscussionPosts: state => state.postList,
  getDiscussionDraftPosts: state => state.draftPostList,
  getSelectedDiscussionPost: state => state.selectedDiscussionPost,
  getSelectedPostResponses: state => state.responseList,
  getTopicName: state => state.topicName,
  getListIsLoading: state => state.postListIsLoading,
  getDraftListIsLoading: state => state.draftPostListIsLoading,
  getIsLearner: state => state.isLearner,
  getIsSearchActive: state => state.isSearchBarActive,
  getAllPostListCount: state => state.allPostListCount,
  getMentionsList: state => state.courseUsersList,
  getUserName: state => state.userName,
  getSubcategoryActive: state => state.isSubCategoryActive
};
