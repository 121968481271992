<template>
  <div>
    <div class="lx-dropdown__btn-text" v-if="variant === 'default'">
      <span class="lx-dropdown__text-left">{{ text }}</span>
      <span class="lx-dropdown__text-right">
        <span v-if="selected && selected.value">{{ selected.value }}</span>
        <b-icon
          class="lx-dropdown__icon"
          icon="chevron-down"
          aria-hidden="true"
        ></b-icon>
      </span>
    </div>
    <div
      class="lx-dropdown__btn-text lxp-dropdown__menu-text"
      v-else-if="variant === 'menu'"
    >
      <img
        src="@/assets/images/layout/user-fluid-layout/language.svg"
        alt="language"
      />
      <span v-if="selected && selected.value">{{ selected.value }}</span>
    </div>
  </div>
</template>
<script>
import { BIcon } from "bootstrap-vue";
export default {
  props: {
    text: String,
    selected: Object,
    variant: {
      type: String,
      default: "default"
    }
  },
  components: {
    "b-icon": BIcon
  }
};
</script>
<style lang="scss" scoped>
.lx-dropdown__btn-text {
  display: flex;
  align-items: center;
  color: #44464e;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  .lx-dropdown__text-right {
    display: flex;
    align-items: center;
    margin-left: 10px;
    text-transform: uppercase;
    color: #0057e0;
    .lx-dropdown__icon {
      margin-left: 5px;
      color: #44464e;
      width: 12px;
      height: 12px;
    }
  }

  &:hover {
    color: #0057e0;

    .lx-dropdown__text-right {
      .lx-dropdown__icon {
        color: #0057e0;
      }
    }
  }
}
.lxp-dropdown__menu-text {
  padding: 3px 5px;
  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: $brand-public-secondary;
    text-transform: uppercase;
    margin-left: 5px;
  }
  &:hover {
    background: #ebf2fd;
    border-radius: 4px;
  }
}

/* rtl layout */
[dir="rtl"] {
  .lx-dropdown__btn-text {
    .lx-dropdown__text-right {
      margin-left: 0;
      margin-right: 10px;
      .lx-dropdown__icon {
        margin-left: 0px;
        margin-right: 5px;
      }
    }
  }
}
</style>
