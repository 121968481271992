<template>
  <div class="lx-select-dd form-floating">
    <div
      :class="[
        'lx-select__div',
        { error: showError, 'lx-select__label--show': text }
      ]"
    >
      <label class="lx-select__label" v-if="value">{{ label }}</label>
      <b-dropdown size="lg" no-caret class="lx-select__dropdown">
        <template v-slot:button-content>
          <div class="lx-select__dd--div">
            <p :class="{ placeholder: !value }">
              {{
                truncateText(
                  value
                    ? typeof value == "object"
                      ? value.name
                      : value
                    : label,
                  35
                )
              }}
            </p>
            <span class="ob-dd__icon">
              <b-img
                :src="require('@/assets/images/icons/arrow-down-black.svg')"
                alt="arrow"
              />
            </span>
          </div>
        </template>
        <b-dropdown-item
          v-for="(r, index) in dropdownArr"
          :key="index"
          href="javascript:;"
          @click="onSelect(r)"
          :class="{ 'active-class': r == value }"
        >
          {{ typeof r == "object" ? r.name : r }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <p class="error-message" v-if="showError">{{ errorMessage }}</p>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    placeholder: String,
    value: String,
    dropdownArr: Array,
    showError: Boolean,
    errorMessage: {
      type: String,
      default: "*This is a mandatory field"
    }
  },
  data() {
    return {
      text: ""
    };
  },
  created() {
    this.text = this.value;
  },
  methods: {
    onSelect(r) {
      this.text = r;
      this.$emit("click", r);
    },
    truncateText(label, maxLength) {
      if (label.length <= maxLength) {
        return label;
      } else {
        return label.slice(0, maxLength) + " ...";
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/_floating.scss";
.lx-select-dd {
  margin-bottom: 25px;
  &:focus-within {
    border: 1px solid $brand-primary !important;
  }
  .lx-select__label {
    display: block;
  }
  .error-message {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #000000;
    margin-top: 5px;
  }
  .lx-select__div {
    width: 100%;
    position: relative;
    &.lx-select__label--show {
      .lx-select__dropdown {
        padding-top: 17px;
      }
    }
    .lx-select__dropdown {
      width: 100%;
      padding: 12px 16px 9px;
      cursor: pointer;
      border: 1px solid transparent;
      &.show {
        border: 1px solid $brand-primary;
        border-radius: 8px;
      }
      .btn {
        background: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        width: 100%;
        &.dropdown-toggle-split {
          text-align: right;
          opacity: 1;
        }
        &:focus,
        &:active {
          background: transparent;
          color: $brand-default-text;
          border: 0;
          box-shadow: none !important;
        }
        .lx-select__dd--div {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          p {
            font-size: 16px !important;
            line-height: 24px;
            color: $brand-neutral-700;
            margin: 0 !important;
            @include floating-label-text;
            @include text-ellipsis;
          }
          .ob-dd__icon {
            font-weight: 600;
          }
        }
      }
      .dropdown-menu {
        width: 100%;
        max-height: 300px;
        overflow: hidden auto;
        background: $brand-neutral-0;
        box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
        transform: translate3d(0px, 62px, 0px) !important;
        border-radius: 8px;
        border: none;
        padding: 0;
        margin: 0 !important;
        .active-class {
          background: #dceaff;
          .dropdown-item {
            color: $brand-primary;
          }
        }
        li {
          border: none;
          padding: 0;
          .dropdown-item {
            padding: 16px 12px;
            @include body-regular;
            outline: none;
            white-space: inherit;

            &:hover {
              background: $brand-primary-100;
              color: $brand-neutral-900;
            }
            &:focus,
            &:active {
              background: $brand-primary-100;
              color: $brand-primary-400;
            }
          }
        }
      }
    }
  }
}

// rtl layout
[dir="rtl"] {
  .lx-select-dd {
    .lx-select__div {
      .lx-select__label {
        text-align: right;
        transform: scale(0.85) translateY(-0.5rem) translateX(3.2rem);
      }
    }
    .b-dropdown {
      .btn {
        p {
          text-align: right;
          float: right;
        }
        .ob-dd__icon {
          right: auto;
          left: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .app-es,
  .app-ru {
    .lx-select__div {
      &.lx-select__label--show {
        .lx-select__label {
          font-size: 11px;
        }
        .lx-select__dropdown {
          padding-top: 30px;
          height: calc(3.5rem + 8px);
        }
      }
    }
  }
}
</style>
