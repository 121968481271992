<template>
  <div class="pagination__wrapper">
    <component
      v-if="showEmptyState"
      :is="emptyStateComponent"
      v-bind="additionalProps"
    />
    <component
      v-else
      :is="dataComponent"
      :data="data"
      v-bind="additionalProps"
    />
    <template v-if="isLoading">
      <div class="d-flex justify-content-center" style="margin: 30px 0;">
        {{ $t("general.loading_ellipsis") }}
      </div>
    </template>
    <template v-else>
      <div
        v-if="showLoadMore"
        class="d-flex justify-content-center"
        style="margin: 30px 0;"
      >
        <Button variant="link" @click="loadMore">
          {{ $t("general.load_more") }}
        </Button>
      </div>
    </template>
  </div>
</template>
<script>
/**
 * This component currently supports only the below response format
 * 
 * {
    "data": {
        "count": 4,
        "next_page": 2,
        "previous_page": null,
        "results": [
          {..},
          {...}
        ]
      }
    }

 */

import axios from "axios";

export default {
  name: "pagination-v2",
  props: {
    dataComponentPath: {
      type: String,
      default:
        "components/WrapperComponents/PaginationV2/SampleDataComponentWrapper.vue"
    },
    emptyStateComponentPath: {
      type: String,
      default: "components/WrapperComponents/PaginationV2/SampleEmptyState.vue"
    },
    dataFetchURL: {
      type: String,
      required: true
    },
    authToken: {
      type: String
    },
    additionalProps: {
      type: Object,
      default: () => {
        return {};
      }
    },
    pageLabel: {
      type: String,
      default: "page"
    },
    pageSizeLabel: {
      type: String,
      default: "page_size"
    },
    pageSizeValue: {
      type: Number,
      default: 10
    },
    countLabel: {
      type: String,
      default: "count"
    },
    nextPageLabel: {
      type: String,
      default: "next_page"
    },
    disablePagination: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      response: null,
      isLoading: true,
      data: [],
      pageNo: 1
    };
  },
  computed: {
    dataComponent() {
      return () => import(`@/${this.dataComponentPath}`);
    },
    emptyStateComponent() {
      return () => import(`@/${this.emptyStateComponentPath}`);
    },
    paginatedDataFetchURL() {
      if (this.dataFetchURL) {
        return `${this.dataFetchURL}?${this.pageSizeLabel}=${this.pageSizeValue}&${this.pageLabel}=${this.pageNo}`;
      }

      return null;
    },
    showLoadMore() {
      if (this.response && this.disablePagination === false) {
        return this.response[this.nextPageLabel] ? true : false;
      }

      return false;
    },
    showEmptyState() {
      if (this.response) {
        return !this.isLoading && this.response[this.countLabel] === 0;
      }

      return false;
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let headers = {};
      if (this.authToken) {
        headers = {
          headers: {
            Authorization: this.authToken
          }
        };
      }
      axios.get(this.paginatedDataFetchURL, headers).then(response => {
        this.response = response.data.data;
        this.data = [...this.data, ...this.response.results];

        this.isLoading = false;
      });
    },
    loadMore() {
      this.isLoading = true;
      this.pageNo = this.pageNo + 1;
      this.fetchData();
    },
    reload() {
      this.pageNo = 0;
      this.data = [];
      this.loadMore();
    }
  }
};
</script>
