<template>
  <div class="lx-form-tags">
    <b-form-tags
      input-id="tags-basic"
      @input="$emit('input', $event)"
      remove-on-delete
      :placeholder="placeholder"
      :autofocus="autofocus"
      :value="value"
    >
    </b-form-tags>
  </div>
</template>
<script>
export default {
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    placeholder: String,
    autofocus: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tags: []
    };
  }
};
</script>
<style lang="scss">
.lx-form-tags {
  .b-form-tags {
    padding: 4px 8px;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    .b-form-tag {
      background: #ffffff;
      border-radius: 8px;
      padding: 4px 8px;
      align-items: center !important;
      .b-form-tag-content {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.25px;
        color: #434343;
      }
      .b-form-tag-remove {
        color: #434343;
        opacity: 1;
        font-size: 20px;
      }
    }
  }
}
</style>
