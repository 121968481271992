<template>
  <span
    :class="[
      'img-btn',
      { hovered: isHovered, focused: isFocused, pressed: isPressed }
    ]"
    @click="handleIconClick"
  >
    <img
      :src="iconUrl"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
      @focus="isFocused = true"
      @blur="isFocused = false"
      @mousedown="isPressed = true"
      @mouseup="isPressed = false"
      @touchstart="isPressed = true"
      @touchend="isPressed = false"
      :alt="altText"
      tabindex="0"
    />
  </span>
</template>
<script>
export default {
  props: {
    defaultIcon: {
      type: String,
      required: true
    },
    hoveredIcon: {
      type: String,
      default: ""
    },
    focusedIcon: {
      type: String,
      default: ""
    },
    pressedIcon: {
      type: String,
      default: ""
    },
    altText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isHovered: false,
      isFocused: false,
      isPressed: false
    };
  },
  computed: {
    iconUrl() {
      if (this.isPressed) {
        return this.pressedIcon || this.defaultIcon;
      } else if (this.isFocused) {
        return this.focusedIcon || this.defaultIcon;
      } else if (this.isHovered) {
        return this.hoveredIcon || this.defaultIcon;
      } else {
        return this.defaultIcon;
      }
    }
  },
  methods: {
    handleIconClick() {
      this.$emit("icon-click");
    }
  }
};
</script>
<style lang="scss" scoped>
.img-btn {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  @include flex-horizontal-center;
  background-color: transparent;
  cursor: pointer;
  &.default-bg {
    background-color: $brand-neutral-50;
  }
  &.hovered {
    background-color: $brand-primary-100;
  }
  &.focused {
    outline: 1px solid $brand-primary-400;
    background-color: $brand-primary-100;
  }
  &.pressed {
    outline: none;
    background-color: transparent;
  }
}
[tabindex="0"] {
  outline: none;
}
</style>
