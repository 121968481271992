<template>
  <div class="lx-rating">
    <b-form-rating
      id="rating-inline"
      inline
      :value="rating"
      no-border
      size="sm"
      color="#1DEBCE"
      :readonly="readonly"
    ></b-form-rating>
  </div>
</template>
<script>
export default {
  props: {
    ratingArr: Array,
    color: {
      type: String,
      default: "green"
    },
    rating: Number,
    readonly: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
.lx-rating {
  display: inline-block;
  .b-rating {
    padding: 0;
    height: auto;
    margin-right: 10px;
    box-shadow: none !important;
  }
}
</style>
