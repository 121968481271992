<template>
  <div class="lx-inprogress-course">
    <b-card
      tag="article"
      class="lx-inprogress-course-large-device c-pointer"
      @mouseleave="dropdownMouseLeave"
      @click="$emit('onMyCardClick')"
    >
      <div class="d-flex flex-column align-items-start">
        <div class="lx-course-card__org d-inline-flex" @click="handleOrgClick">
          {{ org }}
        </div>
        <div class="lx-course-card__title">{{ courseTitle }}</div>
        <ProgressBar :value="progressValue" :max="100" type="slim" :bg="bg" />
      </div>
      <div
        class="d-flex mt-2 justify-content-between"
        @click="stopOnMyCardClick"
      >
        <div class="text-left">{{ progressValue }}%</div>
        <div class="lx-course-card-dropdown">
          <b-dropdown
            size="lg"
            right
            variant="link"
            toggle-class="text-decoration-none"
            ref="lxCourseCardDropdown"
            no-caret
          >
            <template #button-content>
              <Icon
                iconName="three-dots-vertical"
                class="three-dots-vertical"
                :rounded="false"
                variant="dark"
              >
              </Icon>
            </template>
            <b-dropdown-item
              :class="{
                'about-programme-no-bottom-radius': progressValue > 0,
                'about-programme': progressValue === 0
              }"
              @click="aboutProgamme"
            >
              {{ $t("vle.vle_menu.about") }}
            </b-dropdown-item>
            <b-dropdown-item
              class="unenroll"
              @click="unenrollCourse()"
              v-if="progressValue === 0"
            >
              {{ $t("course.unenroll.title") }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-card>

    <!-- mobile view card start-->
    <CourseCardOnMobileView
      :org="org"
      :courseTitle="courseTitle"
      :progressValue="progressValue"
      :bg="bg"
      :pacing="course?.pacing"
      :courseLanguage="course?.language"
      :courseImage="courseImage"
      @onCardClick="$emit('onMyCardClick')"
    ></CourseCardOnMobileView>
    <!-- mobile view card end-->
  </div>
</template>
<script>
import CourseCardOnMobileView from "@/components/Dashboard/CourseCardOnMobileView.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CourseCardOnMobileView
  },
  emit: ["onMyCardClick"],
  props: {
    courseImage: {
      type: String,
      default: require("@/assets/images/illustration/course-illustration.svg")
    },
    org: {
      type: String
    },
    courseTitle: {
      type: String,
      required: true
    },
    progressValue: {
      type: Number,
      required: true,
      default: 0
    },
    bg: {
      type: String,
      required: true,
      default: "bg-1",
      validator: value => {
        // The value must match one of these strings
        return ["bg-1", "bg-2", "bg-3", "bg-4"].indexOf(value) !== -1;
      }
    },
    courseId: {
      type: String
    },
    course: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(["allEnrolledCourses"])
  },
  methods: {
    dropdownMouseLeave() {
      this.$refs.lxCourseCardDropdown.hide();
    },
    aboutProgamme() {
      let routeData = this.$router.resolve({
        name: "Courses Detail",
        params: { id: this.courseId }
      });
      window.open(routeData.href, "_blank");
    },
    unenrollCourse() {
      let inputs = {
        modalId: "unenroll-modal",
        courseId: this.courseId
      };
      this.$emit("unenrollData", inputs);
    },
    handleOrgClick(event) {
      this.stopOnMyCardClick(event);
      this.$emit("orgClick");
    },
    stopOnMyCardClick(event) {
      event.stopPropagation();
    }
  }
};
</script>
<style lang="scss">
[dir="rtl"] {
  .lx-inprogress-course {
    .card {
      .card-body {
        .lx-course-card__org,
        .lx-course-card__title {
          text-align: right;
        }
        .lx-course-card-dropdown {
          .dropdown-menu {
            text-align: right;
          }
        }
      }
    }
  }
}
.lx-inprogress-course {
  display: flex;
  // min-width: 218px;
  .card {
    width: 100%;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    &:hover {
      box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.1);
      transform: scale(1.02);
    }
    .card-img-top {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      height: calc(100% - 130px);
      object-fit: cover;
    }
    .card-body {
      .lx-course-card__org {
        color: $brand-neutral-400;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
        text-align: left;
        padding: 1.5px 4px;
        margin-left: -4px;
        font-weight: 500;
        &:hover {
          background: $brand-neutral-50;
          border-radius: 8px;
        }
      }
      .lx-course-card__title {
        text-align: left;
        @include body-regular;
        color: $brand-neutral-900 !important;
        min-height: 50px;
        margin-bottom: 10px;
        word-break: break-all;
        @include line-clamp(2);
      }
    }
    .lx-course-card-dropdown {
      .dropdown-menu {
        padding: 0 0;
        border-radius: 8px;
        box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
      }
      .btn {
        padding: 0px !important;
      }
    }
  }
  .unenroll {
    .dropdown-item {
      padding: 8px;
      color: $brand-error-400;
      border-radius: 0px 0px 8px 8px;
      &:hover {
        background: $brand-error-100;
      }
      &:focus {
        background: $brand-error-100;
      }
      &:active {
        background: $brand-error-100;
      }
    }
  }
  .about-programme,
  .about-programme-no-bottom-radius {
    .dropdown-item {
      padding: 8px;
      border-radius: 8px 8px 0px 0px;
      &:active {
        color: $brand-primary-400;
        text-decoration: none;
        background-color: $brand-primary-100;
      }
      &:hover {
        text-decoration: none;
        background-color: $brand-primary-100;
      }
      &:focus {
        color: $brand-primary-400;
        text-decoration: none;
        background-color: $brand-primary-100;
      }
    }
  }
  .about-programme-no-bottom-radius {
    .dropdown-item {
      border-radius: 8px 8px 8px 8px;
    }
  }

  // TODO::refactor to use mixins

  &.bg-1 {
    .card {
      color: #ff6a66;
      background-color: #ffe9e9;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    }

    &:hover {
      .card {
        background-color: #ffd9d9;
      }
    }
  }

  &.bg-2 {
    .card {
      color: #1bb40e;
      background-color: #e8fee6;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    }

    &:hover {
      .card {
        background-color: #ddceff;
      }
    }
  }

  &.bg-3 {
    .card {
      color: #6b53ff;
      background-color: #f4f2ff;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    }

    &:hover {
      .card {
        background-color: #efecff;
      }
    }
  }

  &.bg-4 {
    .card {
      color: #ff7a00;
      background-color: #ffebc9;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    }

    &:hover {
      .card {
        background-color: #ffe4b6;
      }
    }
  }
}
.lx-inprogress-course-large-device {
  display: block;
}
@media screen and (max-width: 575px) {
  .lx-inprogress-course-large-device {
    display: none !important;
    .lx-course-card-mobile-view {
      width: 322px;
    }
  }
}
</style>
