<template>
  <div class="error-container h-100">
    <div class="border-error h-100">
      <header class="text-left error-header">
        <img
          src="@/assets/images/logo/whoa-logo-blue-en.svg"
          alt="WHO Academy"
          width="auto"
          height="48"
        />
      </header>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 image-container">
            <img
              src="@/assets/images/illustration/underconstruction-illustration.svg"
              alt="Maintenance mode"
              class="error-image mb-4"
              width="auto"
              height="auto"
            />

            <h4 class="error-heading">
              {{ $t("error.server_maintenance.title") }}
            </h4>
            <p class="error-text mt-3 text-center">
              {{ $t("error.server_maintenance.subtitle") }}
            </p>
            <button
              type="button"
              class="btn btn-error"
              @click="openFeedbackForm"
            >
              {{ $t("dashboard.feedback.button") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import openFeedbackForm from "@/core/mixins/openFeedbackForm.js";
export default {
  mixins: [openFeedbackForm]
};
</script>

<style lang="scss" scoped>
.error-container {
  background-color: #0043ac;
  padding: 30px;
}
.border-error {
  background-color: white;
  border-radius: 25px;
}
.error-header {
  padding-left: 5%;
  padding-top: 2%;
}
.error-image {
  position: relative;
  width: 70%;
}
.image-container {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    background: url(../assets/images/blue-bubble.svg) no-repeat;
    background-position: 25px 0;
    width: 73px;
    height: 55px;
    bottom: 67%;
    right: 0%;
  }
}
.btn-error {
  &::before {
    position: absolute;
    content: "";
    background: url(../assets/images/blue-bubble.svg) no-repeat;
    background-position: 25px 0;
    width: 73px;
    height: 55px;
    top: 0%;
    left: -275%;
  }
  position: relative;
  border: 1px solid #0043ac;
  box-sizing: border-box;
  border-radius: 4px;
  color: #0043ac;
}
.error-heading {
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.2px;
}
.error-text {
  font-size: 16px;
  line-height: 24px;
}
</style>
