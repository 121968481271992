<template>
  <div
    :class="['lx-course-card__body', `lx-course-card__${status.toLowerCase()}`]"
    @mouseleave="dropdownMouseLeave"
  >
    <div class="lx-course-card__content">
      <div class="lx-course-card__org" @click="$emit('orgClick')">
        {{ org }}
      </div>
      <div class="lx-course-card__title" @click="$emit('cardClick')">
        {{ courseTitle }}
      </div>
    </div>
    <div class="lx-course-card__footer" @click.stop="onCardClick">
      <div
        class="lx-course-card__footer--div lx-card__footer--enroll"
        v-if="status === 'TO_ENROLL'"
      >
        <div class="lx-course-card__pacing" :class="pacing">
          <img
            src="@/assets/images/wrapper-components/course-card/self-paced-icon.svg"
            alt="pacing"
            v-if="pacing === 'self'"
          />
          <img
            src="@/assets/images/wrapper-components/course-card/instructor-led-icon.svg"
            alt="pacing"
            v-else
          />
          <span ref="card-footer-pacing">{{
            $t(`data.pacing.${pacing}`)
          }}</span>
        </div>
        <div class="lx-course-card__language">
          <img
            src="@/assets/images/layout/user-fluid-layout/language-grey.svg"
            alt="language"
          />
          {{ courseLanguage }}
        </div>
      </div>

      <div
        class="lx-course-card__footer--div lx-card__footer--enrolled"
        v-if="status === 'ENROLLED'"
      >
        <div class="lx-footer__sections">
          <div class="lx-footer__date">
            <img
              src="@/assets/images/wrapper-components/course-card/calendar.svg"
              alt="calendar"
            />
            <p class="lx-footer__date--content">
              <span>{{ startedText }}</span>
            </p>
          </div>
          <b-dropdown
            class="lx-footer__section__dropdown"
            no-caret
            @show="$emit('onShowDropdown')"
            @hide="$emit('onHideDropdowm')"
            ref="lxCourseCardDropdown"
          >
            <template #button-content>
              <Icon
                iconName="three-dots-vertical"
                class="three-dots-vertical"
                :rounded="false"
                variant="dark"
              >
              </Icon>
            </template>
            <b-dropdown-item
              :class="{
                'about-programme__border-radius':
                  course.metrics.progress_percentage > 0,
                'about-programme': course.metrics.progress_percentage === 0
              }"
              @click="navigateTo(course)"
            >
              {{ $t("vle.menu.about") }}
            </b-dropdown-item>
            <b-dropdown-item
              class="unenroll"
              @click="onUnenroll(course)"
              v-if="course.metrics.progress_percentage === 0"
            >
              {{ $t("course.unenroll.title") }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div
        class="lx-course-card__footer--div lx-card__footer--countinue-learning"
        v-if="status === 'CONTINUE_LEARNING' || status === 'COMPLETED'"
      >
        <div class="lx-card___footer--progress">
          <ProgressBar
            :value="getProgressValue(course.metrics.progress_percentage * 100)"
            :max="max"
            type="slim"
          ></ProgressBar>
          <span
            >{{
              getProgressValue(course.metrics.progress_percentage * 100)
            }}%</span
          >
        </div>
        <div class="lx-footer__sections">
          <div
            class="lx-footer__sections--left"
            :class="{
              'reduce-space': course.metrics.credentials.total_count !== 0
            }"
            ref="card-footer-section"
          >
            <div class="lx-footer__sections--modules">
              <img
                :src="
                  status === 'COMPLETED'
                    ? require('@/assets/images/wrapper-components/course-card/modules.svg')
                    : require('@/assets/images/wrapper-components/course-card/modules-grey.svg')
                "
                alt="modules"
              />
              <span class="lx-footer__sections--content"
                >{{ course.metrics.sections.completed }}/{{
                  course.metrics.sections.all
                }}</span
              >
            </div>
            <div
              class="lx-footer__sections--assessment"
              v-if="course.metrics.assessments.all !== 0"
            >
              <img
                :src="
                  status === 'COMPLETED'
                    ? require('@/assets/images/wrapper-components/course-card/assessment.svg')
                    : require('@/assets/images/wrapper-components/course-card/assessment-grey.svg')
                "
                alt="modules"
              />
              <span class="lx-footer__sections--content"
                >{{ course.metrics.assessments.completed }}/{{
                  course.metrics.assessments.all
                }}</span
              >
            </div>
            <div
              class="lx-footer__sections--certificate"
              v-if="course.metrics.credentials.total_count !== 0"
            >
              <img
                :src="
                  status === 'COMPLETED'
                    ? require('@/assets/images/wrapper-components/course-card/certificate.svg')
                    : require('@/assets/images/wrapper-components/course-card/certificate-grey.svg')
                "
                alt="modules"
              />
              <span
                class="lx-footer__sections--content"
                v-if="course.metrics.credentials"
              >
                {{ course.metrics.credentials.achieved_count }}/{{
                  course.metrics.credentials.total_count
                }}</span
              >
            </div>
          </div>
          <div class="lx-footer__sections--right">
            <b-dropdown
              class="lx-footer__section__dropdown"
              no-caret
              @show="$emit('onShowDropdown')"
              @hide="$emit('onHideDropdowm')"
              ref="lxCourseCardDropdown"
            >
              <template #button-content>
                <Icon
                  iconName="three-dots-vertical"
                  class="three-dots-vertical"
                  :rounded="false"
                  variant="dark"
                >
                </Icon>
              </template>
              <b-dropdown-item
                :class="{
                  'about-programme__border-radius':
                    course.metrics.progress_percentage > 0,
                  'about-programme': course.metrics.progress_percentage === 0
                }"
                @click="navigateTo(course)"
              >
                {{ $t("vle.menu.about") }}
              </b-dropdown-item>
              <b-dropdown-item
                class="unenroll"
                @click="onUnenroll(course)"
                v-if="course.metrics.progress_percentage === 0"
              >
                {{ $t("course.unenroll.title") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div
        class="lx-course-card__footer--div lx-card__footer--archived"
        v-if="status === 'ARCHIVED'"
      >
        <div class="lx-footer__sections">
          <div class="lx-footer__date">
            <img
              src="@/assets/images/wrapper-components/course-card/calendar.svg"
              alt="calendar"
            />
            <div class="lx-footer__date--content">
              <span class="lx-footer__date--text">{{
                $t("course.ended_on", { s: getDate(course.end_date) })
              }}</span>
            </div>
          </div>
          <b-dropdown class="lx-footer__section__dropdown" no-caret>
            <template #button-content>
              <b-icon
                icon="three-dots-vertical"
                class="ac-mc__options"
                style="color: #44464E"
              ></b-icon>
            </template>
            <b-dropdown-item
              :class="{
                'about-programme__border-radius':
                  course.metrics.progress_percentage > 0,
                'about-programme': course.metrics.progress_percentage === 0
              }"
              @click="navigateTo(course)"
            >
              {{ $t("vle.menu.about") }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <b-tooltip
      :target="() => $refs['card-footer-section']"
      triggers="hover focus"
      placement="bottom"
      variant="secondary"
      v-if="status === 'CONTINUE_LEARNING' || status === 'COMPLETED'"
    >
      <div class="lx-course-card__tooltip">
        <p>
          {{ course.metrics.sections.completed }}/{{
            course.metrics.sections.all
          }}
          {{ $t("search.tab.modules") }}
        </p>
        <p v-if="course.metrics.assessments.all !== 0">
          {{ course.metrics.assessments.completed }}/{{
            course.metrics.assessments.all
          }}
          {{ $t("course.tooltip.assessments") }}
        </p>
        <p v-if="course.metrics.credentials.total_count !== 0">
          <span>
            {{ course.metrics.credentials.achieved_count }}/{{
              course.metrics.credentials.total_count
            }}
          </span>
          {{ $t("course.tooltip.awards") }}
        </p>
      </div>
    </b-tooltip>
    <b-tooltip
      :target="() => $refs['card-footer-pacing']"
      triggers="hover"
      placement="bottom"
      variant="secondary"
      v-if="language === 'es' || language === 'fr' || language === 'ru'"
    >
      {{ $t(`data.pacing.${pacing}`) }}
    </b-tooltip>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      max: 0
    };
  },
  props: ["org", "courseTitle", "pacing", "courseLanguage", "course", "status"],
  computed: {
    ...mapGetters(["currentServerTime", "language"]),
    startedText() {
      if (
        moment(this.course.start_date).isSameOrAfter(
          moment(this.currentServerTime)
        )
      ) {
        return this.$t("course.starts_on", {
          s: moment(this.course.start_date).format("MMM Do, YYYY")
        });
      } else {
        return this.$t("course.started_on", {
          s: moment(this.course.start_date).format("MMM Do, YYYY")
        });
      }
    }
  },
  methods: {
    getProgressValue(value) {
      let number = 0;
      number = value ? value.toFixed(1) : 0;
      return parseFloat(number);
    },
    openItem(type) {
      this.$emit("itemClick", type);
    },
    navigateTo(c) {
      this.$router.push({ name: "Courses Detail", params: { id: c.id } });
    },
    onMyCard(c) {
      this.$router.push({ name: "Self Paced", params: { id: c.id } });
    },
    dropdownMouseLeave() {
      this.$refs.lxCourseCardDropdown?.hide();
    },
    dynamicColor(value) {
      if (parseFloat(parseInt(value)) === 1) {
        return "success";
      } else if (parseFloat(parseInt(value)) > 0.74) {
        return "warning";
      } else if (parseFloat(parseInt(value)) > 0.49) {
        return "info";
      } else {
        return "primary";
      }
    },
    onUnenroll(c) {
      let inputs = {
        modalId: "unenroll-modal",
        courseId: c.id
      };
      this.$emit("unenrollModalData", inputs);
    },
    getDate(d) {
      return d ? moment(d).format("MMM Do, YYYY") : null;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/wrapper-components/_course-card.scss";
</style>
