<template>
  <div
    :class="[
      'form-floating',
      { 'form-floating--hover': onHover, 'form-floating--focus': onFocus }
    ]"
  >
    <div v-html="append" class="form-floating__append" v-if="append" />
    <div class="form-floating__div">
      <input
        :id="id"
        ref="input"
        :type="type"
        class="form-control"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        :maxlength="maxlength"
        :minlength="minlength"
        :max="maxvalue"
        :min="minvalue"
        @focus="onFocus = true"
        @blur="
          onFocus = false;
          onHover = false;
        "
        @mouseenter="onHover = true"
        @mouseleave="onHover = false"
        @input="$emit('input', $event.target.value)"
      />
      <label :for="id">{{ label }}</label>
    </div>
    <div class="form-floating__prepend" v-if="prepend">{{ prepend }}</div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: "floatingInput"
    },
    label: String,
    type: {
      type: String,
      default: "text"
    },
    placeholder: String,
    append: String,
    prepend: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number
    },
    minlength: {
      type: Number
    },
    maxvalue: {
      type: Number,
      default: null
    },
    minvalue: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      onHover: false,
      onFocus: false
    };
  }
};
</script>
<style lang="scss" scoped>
@import "@/scss/_floating.scss";
.form-floating__append {
  padding: 0 0 0 16px;
}
.form-floating__prepend {
  padding: 0 16px 0 8px;
}
</style>
