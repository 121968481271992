export default {
  getShowFullPageLoader: state => state.showFullPageLoader,
  getAuthProfile: state => state.authProfile,
  allConfig: state => state.config,
  allAuth: state => state.auth,
  getCurrentModule: state => state.courseModules,
  getSelectedCourse: state => state.selectedCourse,
  getSessionCookie: state => state.sessionCookie,
  getCourseOverview: state => state.courseOverview,
  userStatus: state => state.new_user,
  welcomeModalStatus: state => state.welcome_modal_status,
  getOnboardingStatus: state => state.onboardingStatus,
  allConstants: state => state.constants,
  countries: state => {
    return state.constants && state.constants.countries
      ? state.constants.countries
      : [];
  },
  roles: state => {
    return state.constants && state.constants.roles
      ? state.constants.roles
      : [];
  },
  affiliation: state => {
    return state.constants && state.constants.affiliation
      ? state.constants.affiliation
      : [];
  },
  education: state => {
    return state.constants && state.constants.education
      ? state.constants.education
      : [];
  },
  experience: state => {
    return state.constants && state.constants.experience
      ? state.constants.experience
      : [];
  },
  roleTenures: state => {
    return state.constants && state.constants.role_tenures
      ? state.constants.role_tenures
      : [];
  },
  allCredentials: state => state.credentials,
  getErrors: state => state.error,
  allLanguage: state => state.languages,
  allEnrolledCourses: state => state.enrolledCourses,
  getCredential: state => state.credential,
  language: state => state.language,
  showCompleteProfile: state => state.showCompleteProfile,
  showMaintenancePage: state => state.showMaintenancePage,
  isSessionCookieExists: state => {
    // TODO: check for its expiry
    return (
      state.sessionCookie &&
      Array.isArray(state.sessionCookie) &&
      state.sessionCookie.length > 0
    );
  },
  getUserProfile: state => state.userProfile,
  currentServerTime: state => state.serverTime,
  getPageNotFound: state => state.pageNotFound,

  // for layout
  getExpandSideNav: state => state.expandSideNav,
  getLayout: state => state.layout,
  getLayoutUpdateTriggered: state => state.layoutUpdateTriggered,
  getSystemAlert: state => state.systemAlert,
  isSystemAlertEnabled: state =>
    state.systemAlert && state.systemAlert.enabled && state.showSystemAlert,

  // for vue tour
  vueTourStatus: state => state.vueTourStatus,
  vleUserStatus: state => state.newVLEUser,
  //for discussion forums
  forumsUserStatus: state => state.newForumsUser,
  //for header
  getHeaderFixedStatus: state => state.isHeaderFixed
};
