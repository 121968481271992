<template>
  <div
    :class="[
      'lxp-textarea__wrapper',
      { 'lxp-textarea__error': hasError || hasLimitError }
    ]"
  >
    <div
      :class="[
        'lxp-textarea',
        {
          'post-ob': postOb,
          'lxp-textarea--hover': onHover,
          'lxp-textarea--focus': onFocus
        }
      ]"
    >
      <label for="textarea">{{ label }}</label>
      <b-form-textarea
        id="textarea"
        :placeholder="placeholder"
        v-model="text"
        :rows="rows"
        :value="value"
        no-resize
        @focus="onFocus = true"
        @blur="
          onFocus = false;
          onHover = false;
        "
        @mouseenter="onHover = true"
        @mouseleave="onHover = false"
        @input="onInput"
        @keypress="e => (textLength >= limit ? e.preventDefault() : null)"
      ></b-form-textarea>
    </div>
    <div
      :class="['lxp-textarea__footer', { 'has-support-text': hasSupportText }]"
    >
      <p v-if="hasSupportText" class="lxp-input__footer--support-text">
        {{
          hasLimitError
            ? $t("general.textarea.limit.error_message")
            : supportText
        }}
      </p>
      <div class="lxp-input__footer--limit" v-if="limit != 0">
        {{ textLength }}/{{ limit }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 3
    },
    maxRows: {
      type: Number,
      default: 5
    },
    placeholder: {
      type: String,
      default: "Enter something..."
    },
    postOb: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 160
    },
    value: String,
    label: String,
    supportText: String,
    hasError: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: "",
      onHover: false,
      onFocus: false,
      hasLimitError: false
    };
  },
  computed: {
    hasSupportText() {
      return this.hasLimitError || this.supportText;
    },
    textLength() {
      return this.text ? this.text.length : 0;
    }
  },
  mounted() {
    this.text = this.value;
  },
  methods: {
    onInput() {
      if (this.textLength > this.limit) {
        this.hasLimitError = true;
      } else {
        this.hasLimitError = false;
      }
      this.$emit("hasLimitError", this.hasLimitError);
      this.$emit("input", this.text);
    }
  }
};
</script>
<style lang="scss" scopeds>
.lxp-textarea__wrapper {
  height: 100%;
  // error
  &.lxp-textarea__error {
    .lxp-textarea {
      border: 1px solid $brand-error-400 !important;
      height: 88% !important;
    }
    .lxp-textarea__footer {
      .lxp-input__footer--support-text {
        color: $brand-error-400;
      }
    }
  }
  .lxp-textarea {
    position: relative;
    background: $brand-primary-75;
    border-radius: 8px;
    padding: 8px 16px;
    height: 90% !important;
    &.lxp-textarea--hover {
      background: $brand-primary-100;
    }
    &.lxp-textarea--focus {
      border: 1px solid $brand-primary;
      background: $brand-primary-75;
    }
    // disabled
    &.lxp-textarea__disabled {
      background: $brand-neutral-75;
      border: 1px solid $brand-neutral-300;
      label {
        color: $brand-neutral-300;
      }
    }
    label {
      @include label-medium;
      color: $brand-primary;
      display: flex;
    }
    textarea {
      background: transparent;
      border: none;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: $brand-neutral-700;
      padding: 0;
      &:focus {
        background: transparent;
        box-shadow: none;
      }
      &::placeholder {
        color: $brand-neutral-300;
        font-weight: 400;
      }
    }
    span {
      position: absolute;
      right: 10px;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      letter-spacing: 0.4px;
      color: #52575c;
      bottom: 5px;
    }
    &.post-ob {
      textarea {
        background: #fff;
        font-weight: 500;
        font-size: 13px;
        line-height: 140%;
        color: #25282b;
        border-radius: 4px;
        border: 1px solid #eeeeee;
        &:focus {
          border: 1px solid #0043ac;
          border-radius: 4px;
        }
      }
    }
  }
  .lxp-textarea__footer {
    display: flex;
    justify-content: flex-end;
    &.has-support-text {
      justify-content: space-between;
      margin-top: 4px;
    }
    .lxp-input__footer--support-text,
    .lxp-input__footer--limit {
      @include label-medium;
      color: $brand-neutral-300;
      margin-bottom: 0;
    }
    .lxp-input__footer--support-text {
      padding-left: 5px;
    }
  }
}

[dir="rtl"] {
  .lxp-textarea {
    span {
      text-align: left;
      left: 10px;
    }
  }
}
</style>
