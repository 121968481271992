export default {
  SET_COURSE_VIEW_SELECTOR(state, selector) {
    state.courseViewSelector = selector;
  },
  SET_TIMED_ASSESSMENT_FLAG(state, flag) {
    state.isTimedAssessment = flag;
  },
  SET_TIMED_DATA(state, data) {
    state.timedData = data;
    if (state.timedData.state === "not_started") {
      state.courseViewSelector = 1;
      state.isTimedAssessment = true;
      state.showHeaderNav = true;
    }
    // if status is started then show course view layout and set timed assessment flag to true
    else if (state.timedData.state === "started") {
      state.courseViewSelector = 2;
      state.isTimedAssessment = true;
      state.showHeaderNav = false;
      const currentTime = new Date().toISOString();
      const endTime = state.timedData.ends_at;
      // calculate difference of end time and current time
      const differenceInTime = Math.floor(
        (new Date(endTime) - new Date(currentTime)) / 1e3
      );
      // check condition to get the remaining time
      if (differenceInTime < state.timedData.duration) {
        if (differenceInTime <= 0) {
          state.timedData.duration = 0;
        } else {
          state.timedData.duration = differenceInTime;
        }
      }
    }
    // if status is submitted show completed section layout and set timed assessment flag to false
    else if (state.timedData.state === "submitted") {
      state.courseViewSelector = 3;
      state.isTimedAssessment = false;
      state.showHeaderNav = true;
      state.timedData = {
        duration: 0,
        state: "submitted"
      };
      state.isTimedAssessmentSubmitted = true;
    }
  },
  SET_HEADER_NAV(state, status) {
    state.showHeaderNav = status;
  }
};
