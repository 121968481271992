export default {
  SET_SETTINGS_CURRENT_STATUS(state, status) {
    state.currentStatus = status;
  },
  SET_SETTINGS_CURRENT_SECTION(state, section) {
    state.settingsCurrentSection = section;
    let index = state.sections.findIndex(s => s.id === section.id);
    state.sections[index] = section;
  },
  SET_SETTINGS_FORM_OBJECT(state, form) {
    state.form = form;
  },
  SET_USER_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_PROFILE_PIC(state, payload) {
    state.profile.profile_image_url = payload.profile_image_url;
    payload.rootState.authProfile.profile_image_url = payload.profile_image_url;
  },
  RESET_ALL_SETTINGS_SECTION(state) {
    state.sections.forEach(sec => {
      sec.isEdit = false;
    });
  }
};
