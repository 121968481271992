<template>
  <!-- TODO update to the LxpDropdown component -->
  <div class="lx-dropdown" :class="{ selected: isSelected }">
    <b-dropdown variant="link" no-caret>
      <template #button-content>
        <ButtonContent :text="text" :selected="selected" :variant="variant" />
      </template>
      <b-dropdown-item
        :class="{
          'lx-dropdown__selected-item': selected
            ? o.value === selected.value
            : false
        }"
        v-for="(o, i) in options"
        :key="i"
        href="javascript:;"
        @click="onChange(o)"
        >{{ o.text }}</b-dropdown-item
      >
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import ButtonContent from "./LanguageDropDown/ButtonContent.vue";
export default {
  props: {
    text: {
      type: String,
      default: "Drop down"
    },
    options: {
      type: Array,
      default: () => {
        return [
          {
            value: 1,
            text: "Option 1"
          }
        ];
      }
    },
    defaultValue: [String, Number],
    variant: {
      type: String,
      default: "default"
    }
  },
  components: {
    "b-dropdown": BDropdown,
    "b-dropdown-item": BDropdownItem,
    ButtonContent
  },
  data() {
    return {
      selected: null
    };
  },
  computed: {
    isSelected() {
      if (this.selected) {
        return this.selected.value !== this.defaultValue;
      } else {
        return false;
      }
    }
  },
  watch: {
    selected(val) {
      this.$emit("onSelect", val);
    }
  },
  created() {
    this.updateDefaultValue();
  },
  methods: {
    updateDefaultValue() {
      if (this.defaultValue) {
        let defaultSelection = this.options.filter(
          o => o.value === this.defaultValue
        );
        this.selected =
          defaultSelection.length === 1 ? defaultSelection[0] : null;
      }
    },
    onChange(selectedOption) {
      this.selected = selectedOption;
    }
  }
};
</script>
<style lang="scss">
@include scroll-bar;
.lx-dropdown {
  display: inline-block;
  border-radius: 4px;
  &.selected {
    background: #e5ecf6;
  }
  .dropdown-toggle {
    padding: 0px 8px;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      box-shadow: none;
    }
    &:focus {
      text-decoration: none;
      box-shadow: none;
    }
  }
  .dropdown-menu {
    min-width: 180px;
    background: $brand-neutral-0;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    border: none;
    padding: 8px;
    max-height: 400px;
    overflow: hidden auto;
    .dropdown-item {
      @include label-large($brand-neutral-900, 500);
      padding: 8px 32px 8px 8px;
      border-radius: 4px;
      &:hover {
        background: #d6e4fa;
      }
    }
    .lx-dropdown__selected-item {
      .dropdown-item {
        &::after {
          position: absolute;
          display: inline-block;
          content: "";
          background: url("~@/assets/images/icons/tick-black.svg") no-repeat;
          width: 30px;
          height: 30px;
          right: 0;
          margin-top: 4px;
        }
      }
    }
  }
}
[dir="rtl"] {
  .lx-dropdown {
    text-align: right;
    .dropdown-menu {
      .dropdown-item {
        text-align: right;
        padding: 8px 8px 8px 32px;
      }
      .lx-dropdown__selected-item {
        .dropdown-item {
          &::after {
            left: 0;
            right: auto;
            margin-left: 15px;
          }
        }
      }
    }
  }
}
</style>
