var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    {
      'lxp-icon': true,
      'lxp-icon__rounded': _vm.rounded,
      'lxp-icon__type-two': _vm.type == 2
    },
    _vm.variant
  ],on:{"click":function($event){return _vm.$emit('click')}}},[_c('b-icon',{attrs:{"icon":_vm.iconName,"variant":_vm.variant}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }