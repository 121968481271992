<template>
  <input
    type="range"
    :ref="id"
    :id="id"
    :name="id"
    :min="min"
    :max="max"
    v-model="range"
    @input="$emit('input', range)"
    :step="step"
    class="lxp-input-range styled-slider slider-progress"
  />
</template>
<script>
export default {
  props: {
    id: String,
    min: Number,
    max: Number,
    step: Number,
    value: {
      type: [String, Number]
    }
  },
  data() {
    return {
      range: 0
    };
  },
  watch: {
    value(v) {
      this.range = v;
      let inputRange = document.getElementById(this.id);
      inputRange.style.setProperty("--value", v);
    }
  },
  mounted() {
    this.range = this.value;
    for (let e of document.querySelectorAll(
      'input[type="range"].slider-progress'
    )) {
      e.style.setProperty("--value", e.value);
      e.style.setProperty("--min", e.min === "" ? "0" : e.min);
      e.style.setProperty("--max", e.max === "" ? "100" : e.max);
      e.addEventListener("input", () =>
        e.style.setProperty("--value", e.value)
      );
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin runnable-track {
  height: 4px;
  border: none;
  border-radius: 16px;
  background: $brand-primary-100;
  box-shadow: none;
  box-sizing: border-box;
}
@mixin thumb {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background: $brand-primary-400;
  margin-top: calc(4px * 0.5 - max(32px * 0.5, 8px));
  cursor: pointer;
  border: 8px solid $brand-primary-50;
}
$border-color_2: transparent;

input[type="range"].styled-slider {
  height: 1em;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
  //track styles for firefox,edge,safari
  &::-webkit-slider-runnable-track {
    height: 4px;
    border: none;
    border-radius: 12px;
    background: linear-gradient(to right, #efefef, $brand-primary-100);
    box-shadow: none;
  }

  &::-moz-range-track {
    height: 4px;
    border: none;
    border-radius: 12px;
    background: linear-gradient(to right, #efefef, $brand-primary-100);
    box-shadow: none;
  }
  &::-moz-range-progress {
    background: $brand-primary-400;
  }

  &::-ms-fill-upper {
    background: transparent;
    border-color: $border-color_2;
  }
  &::-ms-fill-lower {
    background: transparent;
    border-color: $border-color_2;
  }

  &::-ms-track {
    height: 4px;
    border-radius: 12px;
    background: linear-gradient(to right, #efefef, $brand-primary-100);
    border: none;
    box-shadow: none;
    box-sizing: border-box;
  }

  // thumb styles
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include thumb;
    &:hover,
    &:active {
      border-color: $brand-primary-100;
      box-shadow: 0 0 2px $brand-primary-100;
    }
  }
  &::-moz-range-thumb {
    @include thumb;
    &:hover,
    &:active {
      border-color: $brand-primary-100;
      box-shadow: 0 0 2px $brand-primary-100;
    }
  }

  &::-ms-thumb {
    @include thumb;
    &:hover,
    &:active {
      border-color: $brand-primary-100;
      box-shadow: 0 0 2px $brand-primary-100;
    }
  }
}
input[type="range"].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 32px + var(--ratio) * (100% - 32px));
  &::-webkit-slider-runnable-track {
    background: linear-gradient($brand-primary-400, $brand-primary-400) 0 /
        var(--sx) 100% no-repeat,
      linear-gradient(to right, #efefef, $brand-primary-100);
  }
  &:hover {
    &::-webkit-slider-runnable-track {
      background: linear-gradient($brand-primary-400, $brand-primary-400) 0 /
          var(--sx) 100% no-repeat,
        linear-gradient(to right, #efefef, $brand-primary-100);
    }
    &::-moz-range-track {
      background: linear-gradient($brand-primary-400, $brand-primary-400) 0 /
          var(--sx) 100% no-repeat,
        linear-gradient(to right, #efefef, $brand-primary-100);
    }
    &::-ms-fill-lower {
      background: $brand-primary-400;
    }
  }
  &:active {
    &::-webkit-slider-runnable-track {
      background: linear-gradient($brand-primary-400, $brand-primary-400) 0 /
          var(--sx) 100% no-repeat,
        linear-gradient(to right, #efefef, $brand-primary-100);
    }
    &::-moz-range-track {
      background: linear-gradient($brand-primary-400, $brand-primary-400) 0 /
          var(--sx) 100% no-repeat,
        linear-gradient(to right, #efefef, $brand-primary-100);
    }
    &::-ms-fill-lower {
      background: $brand-primary-400;
    }
  }
  &::-moz-range-track {
    background: linear-gradient($brand-primary-400, $brand-primary-400) 0 /
        var(--sx) 100% no-repeat,
      linear-gradient(to right, #efefef, $brand-primary-100);
  }
  &::-ms-fill-lower {
    height: 4px;
    border-radius: 12px 0 0 12px;
    margin: -undefined 0 -undefined -undefined;
    background: $brand-primary-400;
    border: none;
    border-right-width: 0;
  }
}
</style>
