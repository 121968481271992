/* *
 * DISCUSSION_VIEW_SELECTOR: possible values
 * 1 - No Posts View
 * 2 - How to use discussions intro page
 * 3 - List of posts when the user selects a category/sub-category
 * 4 - Post Details Page
 * 5 - Add Post Form
 */
export const DISCUSSION_VIEW_SELECTOR = {
  IntroPage: 2,
  PostsList: 3,
  PostDetails: 4,
  AddPostForm: 5
};

export default () => ({
  showDiscussionTopics: [],
  discussionViewSelector: DISCUSSION_VIEW_SELECTOR.IntroPage,
  topicId: "",
  topicName: {
    categoryName: "",
    subCategoryName: ""
  },
  postList: {
    count: 0,
    next_page: null,
    previous_page: null,
    results: [],
    isLoading: true
  },
  allPostListCount: 0,
  draftPostList: {
    count: 0,
    next_page: null,
    previous_page: null,
    results: [],
    isLoading: true
  },
  responseList: {
    count: 0,
    next_page: null,
    previous_page: null,
    results: []
  },
  postListIsLoading: false,
  draftPostListIsLoading: false,
  selectedDiscussionPost: {},
  isLearner: false,
  isSearchBarActive: false,
  courseUsersList: [],
  userName: "",
  isSubCategoryActive: null
});
