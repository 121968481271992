/* eslint-disable no-unused-vars */
import axios from "axios";

axios.defaults.headers.common["Accept-Language"] = "en";

const getAuthHeader = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};

const getAuthHeaderV2WithCookie = (token, cookie) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json;version=2.0",
      Openedxcookie: JSON.stringify(cookie)
    }
  };
};

export function getURL(baseURL, params) {
  return {
    // GET
    GET_TOPICS_LIST: `${baseURL}/courses/${params.courseId}/topics`,
    GET_TOPIC_DETAIL: `${baseURL}/courses/${params.courseId}/topics/${params.topicId}`,
    GET_MY_TEAMS_LIST: `${baseURL}/courses/${params.courseId}/teams`,
    GET_TEAMS_LIST: `${baseURL}/courses/${params.courseId}/topics/${params.topicId}/teams`,
    GET_TEAM_DETAIL: `${baseURL}/courses/${params.courseId}/topics/${params.topicId}/teams/${params.teamId}`,
    GET_TEAM_MEMBERS_DETAIL: `${baseURL}/courses/${params.courseId}/teams/${params.teamId}/membership`,
    LIST_TEAM_DISCUSSIONS: `${baseURL}/discussions/${params.courseId}/topics/${params.topicId}/posts`,
    GET_FEED_DETAIL: `${baseURL}/discussions/posts/${params.feedId}`,

    // POST
    CREATE_TEAM: `${baseURL}/courses/${params.courseId}/topics/${params.topicId}/teams`,
    JOIN_TEAM: `${baseURL}/courses/${params.courseId}/topics/${params.topicId}/teams/${params.teamId}/membership`,
    ADD_POST: `${baseURL}/discussions/posts`
  };
}

export default {
  // GET
  getTopicsList({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_TOPICS_LIST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_TOPICS_LIST", response.data.data.results);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getTopicDetails({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_TOPIC_DETAIL;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_TOPIC_DETAIL", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getMyTeamsList({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_MY_TEAMS_LIST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_MY_TEAMS_LIST", response.data.data.results);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getTeamsList({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_TEAMS_LIST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_TEAMS_LIST", response.data.data.results);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getTeamDetail({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_TEAM_DETAIL;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_TEAM_DETAIL", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  listTeamDiscussions({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).LIST_TEAM_DISCUSSIONS;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_TEAM_DISCUSSION_LIST", response.data.data.results);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  // POST
  createTeam({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).CREATE_TEAM;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.post(URL, params, headers).catch(err => {
      commit("SET_ERRORS", err.response.data);
    });
  },
  // POST
  joinTeam({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).JOIN_TEAM;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.post(URL, params, headers).catch(err => {
      commit("SET_ERRORS", err.response.data);
    });
  },
  addPost({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).ADD_POST;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.post(URL, params, headers).catch(err => {
      commit("SET_ERRORS", err.response.data);
    });
  },
  // DELETE
  leaveTeam({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).JOIN_TEAM;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios.delete(URL, headers).catch(err => {
      commit("SET_ERRORS", err.response.data);
    });
  }
};
