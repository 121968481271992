import Vue from "vue";
import Vuex from "vuex";
import state from "./state/index";
import getters from "./getters/index";
import actions from "./actions/index";
import mutations from "./mutations/index";

// Modules
import discussion from "./modules/discussion/index";
import selfPaced from "./modules/selfPaced/index";
import myLearning from "./modules/myLearning/index";
import timedAssessment from "./modules/timedAssessment/index";
import teams from "./modules/teams/index";
import courseDiscovery from "./modules/courseDiscovery/index";
import settings from "./modules/settings/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    discussion: discussion,
    selfPaced: selfPaced,
    myLearning: myLearning,
    timedAssessment: timedAssessment,
    teams: teams,
    courseDiscovery: courseDiscovery,
    settings: settings
  }
});
