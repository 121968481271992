export default {
  allProfile: state => state.profile,
  getCurrentStatus: state => state.currentStatus,
  getSettingsCurrentSection: state => {
    if (state.settingsCurrentSection) {
      return state.sections.find(s => s.id === state.settingsCurrentSection.id);
    } else {
      return state.settingsCurrentSection;
    }
  },
  form: state => state.form,
  allSections: state => state.sections
};
