export default {
  SET_TOPICS_LIST(state, list) {
    state.topicsList = list;
  },
  SET_TOPIC_DETAIL(state, detail) {
    state.topicDetail = detail;
  },
  SET_MY_TEAMS_LIST(state, teamsList) {
    state.myTeamsList = teamsList;
  },
  SET_TEAMS_LIST(state, list) {
    state.teamsList = list;
  },
  SET_TEAMS_VIEW_SELECTOR(state, selector) {
    state.teamsViewSelector = selector;
  },
  SET_TEAM_DETAIL(state, detail) {
    state.teamDetail = detail;
  },
  SET_RESTRICT_JOIN_TEAMS(state, flag) {
    state.restrictJoinTeams = flag;
  },
  SET_TEAM_DISCUSSION_LIST(state, list) {
    state.discussionList = list;
  }
};
