<template>
  <div class="toast-wrapper">
    <Toast
      id="draft-toast"
      :imgPath="require('@/assets/images/vle/draft-toast.svg')"
      :title="$t('discussions.new_post.toast.draft.title')"
      :description="$t('discussions.new_post.toast.draft.description')"
      :autoHide="false"
    ></Toast>
    <Toast
      id="publish-toast"
      :imgPath="require('@/assets/images/vle/checked-doc.svg')"
      :title="$t('discussions.new_post.toast.publish.title')"
      :description="$t('discussions.new_post.toast.publish.description')"
      :autoHide="false"
    ></Toast>
    <Toast
      id="delete-toast"
      :imgPath="require('@/assets/images/vle/forums/delete.svg')"
      :title="$t('discussions.new_post.toast.delete.title')"
      :description="$t('discussions.new_post.toast.delete.description')"
      :autoHide="false"
    ></Toast>
    <Toast
      id="update-toast"
      :imgPath="require('@/assets/images/vle/edit.svg')"
      :title="$t('discussions.new_post.toast.update.title')"
      :description="$t('discussions.new_post.toast.update.description')"
      :autoHide="false"
    ></Toast>
    <Toast
      id="report-toast"
      :imgPath="require('@/assets/images/vle/edit-doc.svg')"
      :title="$t('discussions.new_post.toast.report.title')"
      :description="$t('discussions.new_post.toast.report.description')"
      :autoHide="false"
    ></Toast>
    <Toast
      id="report-response-toast"
      :imgPath="require('@/assets/images/vle/edit-doc.svg')"
      :title="$t('discussions.new_response.toast.report.title')"
      :description="$t('discussions.new_response.toast.report.description')"
      :autoHide="false"
    ></Toast>
  </div>
</template>
<script>
import Toast from "@/components/SelfPaced/Toast.vue";
export default {
  components: {
    Toast
  }
};
</script>
