<template>
  <div>
    <div class="container p-0 sc-empty">
      <div class="sc-empty__content">
        <img src="@/assets/images/search/search-empty.svg" alt="" />
        <h5>
          {{ $t("search_course.empty.heading") }}
        </h5>
        <p>
          {{ $t("search_course.empty.subheading") }}
        </p>
      </div>
    </div>
    <div class="mt-4">
      <RecommendedCourses
        :heading="`${$t('course_discovery.heading.recommended_courses')}`"
      />
    </div>
  </div>
</template>
<script>
import RecommendedCourses from "../../components/Landing/CourseDetails/RecommendedCourses.vue";
export default {
  components: {
    RecommendedCourses
  }
};
</script>

<style lang="scss" scoped>
.sc-empty {
  padding: 32px;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 8px;
  .sc-empty__content {
    h5 {
      margin-top: 25px;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      color: $brand-public-primary;
      font-family: $font-family;
    }
    p {
      font-family: $font-family;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.1px;
      color: $brand-public-secondary;
      padding: 0 25%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .sc-empty__content {
    margin-top: 64px;
    margin-bottom: 64px;
    h5 {
      font-size: 22px !important;
      padding: 16px;
    }
    p {
      padding: 0 5% !important;
    }
  }
}
</style>
