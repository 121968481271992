import router from "@/router";

export default {
  getRedirectUri() {
    let uri = "",
      route = router.currentRoute;

    // Based on MFA keycloak spike the `redirect_uri` doesn't accept special characters i.e +
    // inorder to overcome this issue we encode course id and construct a url for `redirect_uri` param .
    if (route.name === "Courses Detail") {
      let location = window.location;
      uri = `${location.protocol}//${location.host}/${
        route.path.split("/")[1]
      }/${encodeURIComponent(route.params.id)}`;
    } else {
      uri = window.location.href;
    }
    return uri;
  },

  checkEncodeURI(str) {
    return /%[0-9a-f]{2}/i.test(str);
  }
};
