<template>
  <div class="lx-breadcrumb">
    <b-breadcrumb>
      <b-breadcrumb-item
        v-for="(item, index) in items"
        :key="index"
        @click="navigateTo(item.to)"
        :class="{ active: item.active }"
      >
        <img class="bc__custom-icon" v-if="item.icon" :src="item.icon" />
        {{ item.text }}
      </b-breadcrumb-item>
    </b-breadcrumb>
  </div>
</template>
<script>
/* 
Based on: https://bootstrap-vue.org/docs/components/breadcrumb 

Other possible values:
const items = [
  {
    text: 'Home',
    href: 'https://google.com'
  },
  {
    text: 'Posts',
    to: { name: 'home' }
  },
  {
    text: 'Another Story',
    active: true
  }
]
*/
export default {
  props: {
    items: {
      type: Array,
      default: function() {
        return [
          {
            text: "Admin",
            href: "#"
          },
          {
            text: "Manage",
            href: "#"
          },
          {
            text: "Library",
            active: true
          }
        ];
      }
    }
  },
  methods: {
    navigateTo(to) {
      this.$router.push(to);
    }
  }
};
</script>
<style lang="scss">
.bc__custom-icon {
  margin-left: 0;
  margin-right: 6px;
}
[dir="rtl"] {
  .lx-breadcrumb {
    .breadcrumb {
      .breadcrumb-item + .breadcrumb-item::before {
        padding: 0 0.5em 0 0.5em;
        float: right;
      }
    }
    .bc__custom-icon {
      margin-left: 6px;
      margin-right: 0;
    }
  }
}

.lx-breadcrumb {
  font-size: 11px;
  line-height: 18px;

  .breadcrumb {
    background: transparent;

    .breadcrumb-item {
      a {
        color: $brand-inactive-text;
        text-decoration: none;
      }

      &.active {
        a {
          color: $brand-primary;
        }
      }
    }

    .breadcrumb-item + .breadcrumb-item::before {
      color: $brand-inactive-text;
      content: "›";
      font-size: 18px;
      line-height: 14px;
    }
  }
}
</style>
