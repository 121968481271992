<template>
  <div class="lx-alert">
    <!-- Based on: https://bootstrap-vue.org/docs/components/alert -->
    <b-alert class="h-100" :variant="variant" :dismissible="dismissible" show>
      <div class="d-flex align-items-start justify-content-center h-100">
        <Icon
          v-if="iconName && iconName !== ''"
          :variant="variant"
          class="lx-alert__icon"
          :iconName="iconName"
        />
        <slot></slot>
      </div>
    </b-alert>
  </div>
</template>
<script>
import Icon from "./Icon.vue";
export default {
  props: {
    variant: {
      type: String,
      default: "info",
      validator: value => {
        // The value must match one of these strings
        return (
          ["primary", "primary-lighter", "info", "warning"].indexOf(value) !==
          -1
        );
      }
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: ""
    }
  },
  components: {
    Icon
  }
};
</script>
<style lang="scss">
.lx-alert {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
  padding: 0px 20px;

  .lx-alert__icon {
    margin-right: 18px;
  }

  .alert {
    border-radius: 12px;
    word-break: break-word;

    img {
      margin-right: 10px;
      vertical-align: text-bottom;
    }
    .close {
      opacity: 1;
      font-size: 2.1rem;
      font-weight: 500;
      line-height: 1.5rem;
    }
    a {
      font-weight: 700;
      text-decoration: underline;
    }

    &.alert-info {
      color: #52575c;
      background-color: $brand-primary-100;
      border-color: none;

      .close,
      a {
        color: $brand-primary-100;
      }
    }
    &.alert-warning {
      color: $brand-secondary-text;
      background-color: $brand-warning-bg;
      border-color: $brand-warning;
      .close,
      a {
        color: $brand-warning;
      }
    }
  }
  .teams-alert__content {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #191c1d;
  }
}
// rtl layout
[dir="rtl"] {
  .lx-alert {
    .lx-alert__icon {
      margin: 0 0 0 18px;
    }
    .alert {
      img {
        margin: 0 0 0 10px;
      }
    }
  }
}
</style>
