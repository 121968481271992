<template>
  <div class="lx-shimmer">
    <HorizontalCourseCard v-if="variant === 'horizontal-course-card'" />
    <LandingCourseCard v-else-if="variant === 'landing-course-card'" />
    <InProgressCourseCard v-else-if="variant === 'inProgress-course-card'" />
    <DiscussionForumsPost v-else-if="variant === 'discussion-forums-post'" />
    <CourseDetails v-else />
  </div>
</template>
<script>
import CourseDetails from "./Shimmer/CourseDetails.vue";
import HorizontalCourseCard from "./Shimmer/HorizontalCourseCard.vue";
import LandingCourseCard from "./Shimmer/CourseCardLoader.vue";
import InProgressCourseCard from "./Shimmer/MyCourseCardLoader.vue";
import DiscussionForumsPost from "./Shimmer/DiscussionForumsPost.vue";
export default {
  props: {
    variant: {
      // default will be course details
      type: String
    }
  },
  components: {
    CourseDetails,
    HorizontalCourseCard,
    LandingCourseCard,
    InProgressCourseCard,
    DiscussionForumsPost
  }
};
</script>
<style lang="scss">
@import "../../scss/shimmer.scss";
</style>
