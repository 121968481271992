export default {
  courses: [],
  semanticallySimilarCourses: [],
  pageSize: 20,
  pageNumber: 1,
  count: 0,
  isLoadMore: false,
  searchText: "",
  courseLanguage: "en",
  searchHistory: [],
  selectedMenu: ""
};
