var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'lxp-textarea__wrapper',
    { 'lxp-textarea__error': _vm.hasError || _vm.hasLimitError }
  ]},[_c('div',{class:[
      'lxp-textarea',
      {
        'post-ob': _vm.postOb,
        'lxp-textarea--hover': _vm.onHover,
        'lxp-textarea--focus': _vm.onFocus
      }
    ]},[_c('label',{attrs:{"for":"textarea"}},[_vm._v(_vm._s(_vm.label))]),_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":_vm.placeholder,"rows":_vm.rows,"value":_vm.value,"no-resize":""},on:{"focus":function($event){_vm.onFocus = true},"blur":function($event){_vm.onFocus = false;
        _vm.onHover = false;},"mouseenter":function($event){_vm.onHover = true},"mouseleave":function($event){_vm.onHover = false},"input":_vm.onInput,"keypress":e => (_vm.textLength >= _vm.limit ? e.preventDefault() : null)},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('div',{class:['lxp-textarea__footer', { 'has-support-text': _vm.hasSupportText }]},[(_vm.hasSupportText)?_c('p',{staticClass:"lxp-input__footer--support-text"},[_vm._v(" "+_vm._s(_vm.hasLimitError ? _vm.$t("general.textarea.limit.error_message") : _vm.supportText)+" ")]):_vm._e(),(_vm.limit != 0)?_c('div',{staticClass:"lxp-input__footer--limit"},[_vm._v(" "+_vm._s(_vm.textLength)+"/"+_vm._s(_vm.limit)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }