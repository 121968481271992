export default {
  data() {
    return {
      cVariant: "",
      cTitle: ""
    };
  },
  computed: {
    titleName() {
      let text = "";
      if (this.cVariant === "primary") {
        text = "Notification";
      } else if (this.cVariant === "success") {
        text = "Success";
      } else if (this.cVariant === "warning") {
        text = "Alert";
      } else if (this.cVariant === "error") {
        text = "Warning";
      }
      return text;
    },
    toastIcon() {
      let icon = "";
      if (this.cVariant === "primary") {
        icon = "info-circle-fill";
      } else if (this.cVariant === "success") {
        icon = "check-circle-fill";
      } else if (this.cVariant === "warning") {
        icon = "info-circle-fill";
      } else if (this.cVariant === "error") {
        icon = "exclamation-triangle-fill";
      }
      return icon;
    },
    getTitle() {
      const h = this.$createElement;
      const vNodesTitle = h(
        "div",
        { class: ["d-flex", "flex-grow-1", "align-items-center", "mr-2"] },
        [
          h("b-icon", { attrs: { icon: `${this.toastIcon}` } }),
          h(
            "strong",
            { class: "ml-2" },
            `${this.cTitle ? this.cTitle : this.titleName}`
          )
        ]
      );
      return vNodesTitle;
    }
  },
  methods: {
    createToast(message, title = null, variant = "primary") {
      this.cVariant = variant;
      this.cTitle = title;
      this.$bvToast.toast(message, {
        title: this.getTitle,
        toaster: "b-toaster-bottom-center",
        solid: true,
        variant: variant,
        autoHideDelay: 5000
      });
    }
  }
};
