import store from "@/store";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapGetters(["allLanguage", "language"]),
    getLocalStorageLanguage() {
      return localStorage.getItem("user-language");
    }
  },
  methods: {
    checkAndSetNewUserLanguage() {
      if (
        this.getLocalStorageLanguage === null ||
        this.getLocalStorageLanguage === "null"
      ) {
        let lang = "en",
          browserLanguage = window.navigator.language;
        if (this.$route.query && this.$route.query.lang) {
          lang = this.$route.query.lang;
        } else if (browserLanguage) {
          let language_ids = this.allLanguage.map(a => a.id);
          if (
            language_ids.find(l => browserLanguage.startsWith(l)) !== undefined
          ) {
            lang = language_ids.find(l => browserLanguage.startsWith(l));
          }
        }
        this.setUserLanguage(lang);
      } else {
        this.setUserLanguage(this.getLocalStorageLanguage, false);
      }
    },
    setUserLanguage(language, setLocalStorage = true) {
      if (setLocalStorage) {
        localStorage.setItem("user-language", language);
      }
      store.commit("SET_LANGUAGE", language);
    },
    setSelectedLanguage(lang) {
      this.$i18n.locale = lang;
      let locale = lang === "zh" ? "zh_cn" : lang;
      moment.locale(locale);
      if (this.$keycloak.authenticated) {
        this.$store.dispatch("updateProfile", { language: lang });
      } else {
        this.setUserLanguage(lang);
      }
    },
    getAllLanguage() {
      const lang = this.allLanguage.map(l => ({
        ...l,
        text: this.$i18n.t(`data.language.${l.id}`)
      }));

      return lang;
    },
    parentClass() {
      if (!this.$parent.$route.meta.requiresAuth) {
        return "landing";
      } else {
        return "user-layout";
      }
    }
  }
};
