import i18n from "../../../i18next";
export default {
  SET_DISCUSSION_TOPICS(state, title) {
    state.showDiscussionTopics = title;
  },
  SET_DISCUSSION_VIEW_SELECTOR(state, selector) {
    state.discussionViewSelector = selector;
  },
  SET_TOPIC_ID(state, id) {
    state.topicId = id;
  },
  SET_TOPIC_NAME(state, namesObject) {
    state.topicName = {
      categoryName: namesObject.categoryName,
      subCategoryName: namesObject.subCategoryName
    };
  },
  SET_ALL_POSTS_ACTIVE(state) {
    state.topicId = "";
    state.topicName = {
      categoryName: i18n.t("discussions.post.all_posts"),
      subCategoryName: ""
    };
  },
  SET_LIST_POST(state, list) {
    if (list.previous_page !== null) {
      list.results = [...state.postList.results, ...list.results];
    }
    state.postList = list;
  },
  SET_ALL_LIST_COUNT(state, count) {
    state.allPostListCount = count;
  },
  RESET_LIST_POST(state) {
    state.postList = {
      count: 0,
      next_page: null,
      previous_page: null,
      results: []
    };
  },
  SET_LIST_POST_LOADING(state, value) {
    state.postListIsLoading = value;
  },
  SET_DRAFT_LIST_POST(state, list) {
    if (list.previous_page !== null) {
      list.results = [...state.draftPostList.results, ...list.results];
    }
    state.draftPostList = list;
  },
  SET_DRAFT_LIST_POST_LOADING(state, value) {
    state.draftPostListIsLoading = value;
  },
  SET_SELECTED_DISCUSSION_POST(state, selectedPost) {
    state.selectedDiscussionPost = selectedPost;
  },
  RESET_SELECTED_DISCUSSION_POST(state) {
    state.selectedDiscussionPost = {};
  },
  SET_POST_RESPONSES(state, response) {
    if (response.previous_page !== null) {
      response.results = [...state.responseList.results, ...response.results];
    }
    state.responseList = response;
  },
  RESET_POST_RESPONSES(state) {
    state.responseList = {
      count: 0,
      next_page: null,
      previous_page: null,
      results: []
    };
  },
  SET_IS_LEARNER(state, value) {
    state.isLearner = value;
  },
  SET_IS_SEARCH_ACTIVE(state, value) {
    state.isSearchBarActive = value;
  },
  SET_COURSE_USERS_LIST(state, value) {
    state.courseUsersList = value;
  },
  SET_USER_NAME(state, value) {
    state.userName = value;
  },
  SET_SUB_CATEGORY_ACTIVE(state, value) {
    state.isSubCategoryActive = value;
  }
};
