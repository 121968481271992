<template>
  <div :title="courseTitle" :class="['lx-course-card', layout]">
    <div
      class="lx-course-card--div d-none d-sm-block d-md-block d-lg-block"
      @click="onCardClick"
    >
      <div class="lx-course-card__img">
        <img
          :src="placeholderImage || courseImage"
          alt="course image"
          class="lx-cc__img"
          @error="handleError"
        />
        <div class="card-tag" v-if="isNew">New</div>
      </div>
      <CardContent
        :org="org"
        :courseTitle="courseTitle"
        :pacing="pacing"
        :courseLanguage="courseLanguage"
        :course="course"
        :status="status"
        @unenrollModalData="unenrollModalData"
        @orgClick="onOrgClicked"
        @itemClick="onItemClick"
        @cardClick="onCardClick"
      />
    </div>

    <!-- mobile view card start-->
    <div class="d-block d-sm-block d-md-none d-lg-none">
      <CourseCardOnMobileView
        :org="org"
        :courseTitle="courseTitle"
        :pacing="pacing"
        :courseLanguage="courseLanguage"
        @onMyCardClick="onCardClick"
        :status="status"
        @unenrollModalData="unenrollModalData"
        :placeholderImage="placeholderImage"
        :courseImage="courseImage"
      >
      </CourseCardOnMobileView>
    </div>
    <!-- mobile view card end-->
  </div>
</template>
<script>
import CardContent from "./CourseCard/CardContent.vue";
import CourseCardOnMobileView from "./CourseCardOnMobileView.vue";
export default {
  data() {
    return {
      placeholderImage: "",
      orgClicked: false,
      elementClicked: false,
      isDropdownVisible: false // to prevent overalpping of cards and dropdown item
    };
  },
  props: {
    courseImage: {
      type: String
    },
    org: {
      type: String
    },
    courseTitle: {
      type: String,
      required: true
    },
    pacing: {
      type: String,
      required: true,
      validator: value => {
        // The value must match one of these strings
        return ["self", "instructor"].indexOf(value) !== -1;
      }
    },
    isNew: {
      type: Boolean,
      default: false
    },
    course: {
      type: Object,
      required: true
    },
    status: {
      type: String,
      default: "TO_ENROLL"
    },
    currentServerTime: String,
    courseLanguage: {
      type: String,
      required: true,
      default: "en",
      validator: value => {
        // The value must match one of these strings
        return (
          [
            "ar",
            "zh",
            "en",
            "fr",
            "ru",
            "es",
            "bs",
            "cs",
            "et",
            "fil",
            "hr",
            "hy",
            "it",
            "lt",
            "pl",
            "pt",
            "ro",
            "sl",
            "tr",
            "uk"
          ].indexOf(value) !== -1
        );
      }
    },
    layout: {
      type: String,
      default: "v",
      validator: value => {
        // The value must match one of these strings
        return ["v", "h"].indexOf(value) !== -1;
      }
    }
  },
  components: { CardContent, CourseCardOnMobileView },
  methods: {
    unenrollModalData(data) {
      this.$emit("unenrollModalData", data);
    },
    handleError() {
      if (window.innerWidth <= 575) {
        this.placeholderImage = require("@/assets/images/not-found/course-img-not-found-responsive.svg");
      } else {
        this.placeholderImage = require("@/assets/images/not-found/course-img-not-found.svg");
      }
    },
    onOrgClicked() {
      this.elementClicked = true;
      this.$emit("orgClick");
    },
    onCardClick() {
      if (!this.elementClicked) {
        this.$emit("click");
      }
    },
    onItemClick(type) {
      this.elementClicked = true;
      let obj = {
        type: type,
        course: this.course
      };
      this.$emit("itemClick", obj);
    }
  }
};
</script>
<style lang="scss">
[dir="rtl"] {
  .lx-course-card {
    .card {
      .card-body {
        text-align: right;

        .lx-course-card__footer {
          .lx-course-card__pacing {
            padding: 0 0 0 30px;
            margin: 0 0 0 30px;
            border-left: 1px solid #a0a4a8;
            border-right: none;
          }

          img {
            margin: 0;
          }
        }
      }
    }
  }
}

.lx-course-card {
  .lx-course-card--div {
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    border-radius: 16px;
    border: none;
    cursor: pointer;
    background: #ffffff;
    min-width: 190px;
    max-width: 560px;
    height: 272px;
    .lx-course-card__img {
      height: 120px;

      .lx-cc__img {
        height: 120px;
        object-fit: cover;
        object-position: center;
        width: 100%;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }

      .card-tag {
        @include label-medium;
        background: $brand-secondary1-400;
        border-radius: 8px;
        color: #fff;
        position: absolute;
        right: 8px;
        top: 8px;
        padding: 4px 8px;
      }
    }

    &:hover {
      box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.1);
      transform: scale(1.02);
    }
  }
  &.lx-course-card__no-hover {
    .lx-course-card--div {
      &:hover {
        transform: none;
      }
    }
  }
}
</style>
