<template>
  <div
    class="lx-course-card-mobile-view c-pointer"
    @click="$emit('onMyCardClick')"
  >
    <b-card no-body class="overflow-hidden h">
      <div class="d-flex justify-content-start">
        <div class="lx-course-card__img">
          <img
            :src="placeholderImage || courseImage"
            class="lx-cc__img"
            @error="handleError"
          />
        </div>
        <div class="w-100 pl-2">
          <b-card-body v-if="progressValue">
            <div class="lx-course-card__org text-left">{{ org }}</div>
            <div class="lx-course-card__title">{{ courseTitle }}</div>
            <ProgressBar
              :value="progressValue"
              :showPrgressValue="showPrgressValue"
              :max="100"
              type="slim"
              :bg="bg"
            />
          </b-card-body>
          <b-card-body v-else>
            <CardContent
              :org="org"
              :courseTitle="courseTitle"
              :pacing="pacing"
              :courseLanguage="courseLanguage"
              :status="status"
            />
          </b-card-body>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import CardContent from "./CourseCardMobile/MobileCardContent.vue";
export default {
  emit: ["onMyCardClick"],
  components: {
    CardContent
  },
  props: {
    courseImage: {
      type: String
    },
    org: {
      type: String
    },
    courseTitle: {
      type: String
    },
    pacing: {
      type: String,
      validator: value => {
        // The value must match one of these strings
        return ["self", "instructor"].indexOf(value) !== -1;
      }
    },
    courseLanguage: {
      type: String,
      default: "en",
      validator: value => {
        // The value must match one of these strings
        return (
          [
            "ar",
            "zh",
            "en",
            "fr",
            "ru",
            "es",
            "bs",
            "cs",
            "et",
            "fil",
            "hr",
            "hy",
            "it",
            "lt",
            "pl",
            "pt",
            "ro",
            "sl",
            "tr",
            "uk"
          ].indexOf(value) !== -1
        );
      }
    },
    progressValue: {
      type: Number,
      default: 0
    },
    bg: {
      type: String,
      default: "bg-1",
      validator: value => {
        // The value must match one of these strings
        return ["bg-1", "bg-2", "bg-3", "bg-4"].indexOf(value) !== -1;
      }
    },
    status: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showPrgressValue: true,
      placeholderImage: ""
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleError);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleError);
  },
  methods: {
    handleError() {
      if (window.innerWidth <= 480) {
        this.placeholderImage = require("@/assets/images/not-found/course-img-not-found-responsive.svg");
      } else {
        this.placeholderImage = require("@/assets/images/not-found/course-img-not-found.svg");
      }
    }
  }
};
</script>
