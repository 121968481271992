export default {
  GET_ALL_COURSES(state, { data, includingSemanticResults }) {
    let courses = data.results;
    if (state.pageNumber > 1) {
      state.courses = [...state.courses, ...courses];
    } else {
      state.courses = courses;
    }
    if (includingSemanticResults) {
      // NOTE: unlike exact results, semantic results are not paginated and do
      // not require accumulation:
      state.semanticallySimilarCourses = data.semantic_results;
    }
    data.next === null ? (state.isLoadMore = false) : (state.isLoadMore = true);
  },
  SET_PAGE_SIZE(state, pageSize) {
    state.pageSize = pageSize;
  },
  SET_PAGE_NUMBER(state, pageNumber) {
    state.pageNumber = pageNumber;
  },
  SET_COUNT(state, count) {
    state.count = count;
  },
  SET_LOAD_MORE(state, isLoadMore) {
    state.isLoadMore = isLoadMore;
  },
  SET_SEARCH_TEXT(state, searchText) {
    state.searchText = searchText;
  },
  SET_COURSE_LANGUAGE(state, language) {
    state.courseLanguage = language;
  },
  SET_SEARCH_HISTORY_LIST(state, data) {
    state.searchHistory = data;
  },
  UPDATE_SEARCH_HISTORY(state, history) {
    let index = state.searchHistory.findIndex(h => h.id === history.id);
    state.searchHistory.splice(index, 1);
  },
  SET_SELECTED_MENU(state, menuItem) {
    state.selectedMenu = menuItem;
  }
};
