<template>
  <b-form-checkbox-group
    v-model="selected"
    :options="options"
    class="lxp-checkbox__group"
    value-field="item"
    text-field="name"
    disabled-field="notEnabled"
    @input="onChange"
    :stacked="stacked"
  ></b-form-checkbox-group>
</template>

<script>
export default {
  props: {
    options: Array,
    stacked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: []
    };
  },
  methods: {
    onChange() {
      this.$emit("input", this.selected);
    }
  }
};
</script>

<style lang="scss">
.lxp-checkbox__group {
  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &::after {
          //   background-image: url("/assets/images/tick-white.svg");
          box-sizing: border-box;
          border-radius: 3px;
        }
        &::before {
          background-color: #0043ac;
          border-color: #0043ac;
        }
      }
    }
  }
  .custom-control-label {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #757575;
    &::before {
      background: #ffffff;
      border: 1.5px solid #757575;
      border-radius: 3px;
      box-shadow: none !important;
    }
  }
}
</style>
