<template>
  <div class="lx-listitem">
    <Milestone type="minor" />
    <slot></slot>
  </div>
</template>
<script>
import Milestone from "./Milestone.vue";
export default {
  components: {
    Milestone
  }
};
</script>
<style lang="scss">
.lx-listitem {
  font-size: 14px;
  line-height: 22px;
  color: #52575c;
  margin-bottom: 15px;
}
</style>
