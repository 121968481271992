<template>
  <b-button
    @click="$emit('click')"
    :variant="variant"
    :pill="pill"
    :disabled="disabled"
    :class="className"
    :size="size"
    :block="block"
    ><slot></slot
  ></b-button>
</template>
<script>
export default {
  props: {
    variant: {
      type: String,
      default: "primary",
      validator: value => {
        // The value must match one of these strings
        return (
          [
            "primary",
            "primary-lighter",
            "outline-primary",
            "info",
            "outline-info",
            "grey",
            "warning",
            "outline-warning",
            "primary-vle",
            "outline-primary-vle",
            "link",
            "outline-danger", // used in VLE Exit Modal
            "custom-primary-vle", // used for logout modal and Unenroll sucsess modal
            "primary-lighter-home" // used in Home Alerts
          ].indexOf(value) !== -1
        );
      }
    },
    pill: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "sm",
      validator: value => {
        return ["sm", "lg", "default"].indexOf(value) !== -1;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.btn {
  @include button-label;
  border-radius: 100px;
  height: $btn-default-height;
  padding: $btn-padding-x-default $btn-padding-y-default;
  @include flex-horizontal-center;

  &:focus {
    box-shadow: none;
  }

  &.disabled {
    cursor: not-allowed;
    border-color: $brand-neutral-75;
    background-color: $brand-neutral-75 !important;
    color: $brand-neutral-300 !important;
  }

  // size
  &.btn-sm {
    padding: $btn-padding-x-sm $btn-padding-y-sm;
    height: $btn-sm-height;
  }
  &.btn-lg {
    padding: $btn-padding-x-lg $btn-padding-y-lg;
    height: $btn-lg-height;
  }

  // outline-primary
  &.btn-outline-primary {
    color: $brand-primary;
    border-color: $brand-primary;

    &:hover,
    &:focus {
      background-color: $brand-primary-100;
    }
    &:active {
      border-color: $brand-primary-400;
      background-color: $brand-primary-100;
      color: $brand-primary-400;
      &:focus {
        box-shadow: none;
      }
    }
  }

  // outline style
  &.btn-outline-info,
  &.btn-outline-primary,
  &.btn-outline-primary-vle,
  &.btn-outline-warning,
  &.btn-outline-danger {
    box-shadow: none;
    border: 2px solid;
    outline: none;
  }
  // primary-ligher
  &.btn-primary-lighter {
    border-color: $brand-primary-lighter;
    background-color: $brand-primary-lighter;
    color: #fff;
    &:hover {
      background-color: $brand-primary-lighter;
    }
  }
}

// primary
.btn-primary {
  border: none;
  background-color: $brand-primary;
  color: $button-primary-text;
  &:hover,
  &:focus {
    background-color: $button-primary-hover;
    color: $button-primary-hover-text;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  }
  &:active {
    background-color: $brand-primary !important;
    color: $button-primary-text !important;
    border: none !important;
  }
  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

// outline-danger used in VLE Exit modal
.btn-outline-danger {
  color: $brand-error-400 !important;
  border-color: $brand-error-400 !important;
  &:hover,
  &:focus {
    background-color: $brand-error-100;
  }
  &:active {
    background-color: #ffffff !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}

// primary-vle
.btn-primary-vle {
  border-color: $brand-vle-primary;
  background-color: $brand-vle-primary;
  color: #fff;
  padding: 10px 24px;
}

// outline-vle
.btn-outline-primary-vle {
  color: $brand-vle-primary;
  border-color: $brand-vle-primary;
  padding: 10px 24px;

  &:hover {
    background-color: transparent;
  }
}

// grey
.btn-grey {
  border-color: $brand-disabled-grey;
  background-color: $brand-disabled-grey;
  color: #fff;
}

// info
.btn-info {
  border-color: #00bfa4;
  background-color: #00bfa4;

  &:hover {
    border-color: #1bd2b9;
    background-color: #1bd2b9;
  }
}

// outline-info
.btn-outline-info {
  color: #00bfa4;
  border-color: #00bfa4;

  &:hover {
    background-color: #d1f1ed;
  }
}

// outline-info
.btn-outline-transparent {
  border: 1px solid #0057e0;
  border-radius: 100px;
  font-family: $font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #0057e0;
  background-color: white;

  &:hover {
    background-color: white;
  }
}
// warning
.btn-warning {
  background: $brand-warning !important;
  border-color: $brand-warning !important;
  color: white !important;
}
.btn-outline-warning {
  color: $brand-warning !important;
  border: none;
  &:hover,
  &:active {
    background: transparent !important;
  }
}

// link
.btn-link {
  color: $brand-primary;
  text-decoration: none;
}

// public page button
.lp-button__height {
  padding: 18px 20px;
  font-weight: 600;
}
//
.btn-custom-primary-vle {
  border-color: $brand-primary;
  background-color: $brand-primary;
  color: $button-primary-text;
  &:hover,
  &:focus {
    background-color: $brand-primary;
    color: $button-primary-hover-text;
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  }
  &:active {
    background-color: $brand-primary !important;
    color: $button-primary-text !important;
    border: none !important;
  }
  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

.btn-primary-lighter-home {
  border-color: $brand-primary-400;
  background-color: $brand-primary-400;
  color: #fff;
}
</style>
