<template>
  <div :class="[{ 'lx-milestone': true }, type]"></div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "major",
      validator: value => {
        // The value must match one of these strings
        return ["major", "minor"].indexOf(value) !== -1;
      }
    }
  }
};
</script>
<style lang="scss">
[dir="rtl"] {
  .lx-milestone {
    margin: 0 0 0 10px;
  }
}
.lx-milestone {
  display: inline-block;
  width: 17px;
  height: 17px;
  vertical-align: text-bottom;
  margin-right: 10px;
  border: 3px solid #fff;
  border-radius: 100%;
  background: $brand-primary;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

  &.minor {
    border: none;
    width: 5px;
    height: 5px;
    vertical-align: middle;
    box-shadow: none;
  }
}
</style>
