import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["language", "allLanguage"]),
    logoLanguage() {
      let officialLanguages = this.allLanguage
        .filter(l => l.group === "un")
        .map(al => al.id);
      if (officialLanguages.includes(this.language)) {
        return this.language;
      } else {
        return "en";
      }
    }
  }
};
