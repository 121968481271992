<template>
  <div
    :class="[
      {
        'lxp-icon': true,
        'lxp-icon__rounded': rounded,
        'lxp-icon__type-two': type == 2
      },
      variant
    ]"
    @click="$emit('click')"
  >
    <b-icon :icon="iconName" :variant="variant"></b-icon>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Number,
      default: 1,
      validator: value => {
        // The value must match one of these strings
        return [1, 2].indexOf(value) !== -1;
      }
    },
    iconName: {
      type: String,
      default: null
    },
    rounded: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "#4488F4"
    },
    variant: {
      type: String,
      default: "primary",
      validator: value => {
        // The value must match one of these strings
        return (
          [
            "primary",
            "primary-lighter",
            "outline-primary",
            "info",
            "outline-info",
            "grey",
            "warning",
            "outline-warning",
            "dark",
            "success"
          ].indexOf(value) !== -1
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.lxp-icon {
  cursor: pointer;
  &.lxp-icon__type-two {
    &.lxp-icon__rounded {
      padding: 20px;
      &.grey {
        background: transparent;
        border: 1px solid $brand-default-text;
      }
      &.primary {
        background: $brand-vle-primary;
        .b-icon {
          color: #fff !important;
        }
      }
      &[disabled] {
        opacity: 0.5;
      }
    }
  }
  &.lxp-icon__rounded {
    padding: 9px;
    display: inline-flex;
    border-radius: 100%;
    &.primary {
      background: $brand-primary-bg;
    }
    &.grey {
      background: $brand-grey-bg;
    }
    &[disabled] {
      opacity: 0.5;
      cursor: pointer;
      pointer-events: none;
    }
  }
  .b-icon {
    font-size: 20px;
  }
}
</style>
