<template>
  <div>
    <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks">
      <template slot-scope="tour">
        <transition name="fade" mode="out-in">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :isFirst="tour.isFirst"
            :isLast="tour.isLast"
            :labels="tour.labels"
            :next-step="tour.nextStep"
            :skip="tour.skip"
            :step="tour.steps[tour.currentStep]"
            :stop="tour.stop"
            :highlight="true"
            :class="tour.steps[tour.currentStep].target.slice(1)"
          >
            <template>
              <div slot="header" class="w-100">
                <span
                  v-for="(step, index) in steps"
                  :key="index"
                  @click="$emit('bulletClick', index - 1)"
                  class="vt__bullets--div"
                >
                  <button
                    class="bullet-button"
                    @click="$tours['myTour'].start()"
                    v-if="index === 0"
                  >
                    <div
                      class="vt__bullets--first-rectangle"
                      v-if="$tours['myTour'].isFirst"
                    >
                      <svg
                        width="16"
                        height="6"
                        viewBox="0 0 16 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="16" height="6" rx="3" fill="#0081FF" />
                      </svg>
                    </div>
                    <div v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33"
                        height="33"
                        fill="currentColor"
                        class="bi bi-dot"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      </svg>
                    </div>
                  </button>

                  <button
                    class="bullet-button"
                    @click="$tours['myTour'].start(index)"
                    v-else
                  >
                    <div
                      class="vt__bullets--other-rectangle"
                      v-if="$tours['myTour'].currentStep == index"
                    >
                      <svg
                        width="16"
                        height="6"
                        viewBox="0 0 16 6"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="16" height="6" rx="3" fill="#0081FF" />
                      </svg>
                    </div>
                    <div v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="33"
                        height="33"
                        fill="currentColor"
                        class="bi bi-dot"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      </svg>
                    </div>
                  </button>
                </span>
              </div>
              <div slot="actions" class="w-100">
                <div class="vt-actions__section" v-if="!tour.isLast">
                  <a class="vt-actions__cta skip" @click="tour.skip">
                    {{ $t("general.skip") }}
                  </a>
                  <a class="vt-actions__cta next" @click="tour.nextStep">
                    {{ $t("general.next") }}
                  </a>
                </div>
                <div class="vt-actions__section" v-else>
                  <a class="vt-actions__cta repeat" @click="repeatTour()">{{
                    $t("general.repeat")
                  }}</a>
                  <a class="vt-actions__cta close-tour" @click="closeTour()">{{
                    $t("general.close")
                  }}</a>
                </div>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true
    },
    myCallbacks: {
      type: Object
    }
  },
  methods: {
    repeatTour() {
      this.$emit("repeatTour");
      this.$tours["myTour"].start();
    },
    closeTour() {
      this.$emit("closeTour");
      this.$tours["myTour"].finish();
      this.$store.commit("SET_VUE_TOUR_STATUS", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.vt__bullets--div {
  .bullet-button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0px;
    color: $brand-neutral-700;
    font-size: 1.5em;
    .vt__bullets--first-rectangle {
      padding-right: 5px;
    }
    .vt__bullets--other-rectangle {
      padding: 0 5px;
    }
  }
  &:first-child {
    .bullet-button {
      padding-left: 0;
    }
  }
}
.selfPlacedMenuIcon {
  right: 171px !important;
  top: 65px !important;
}
.vt-actions__section {
  @include horizontal-space-between;
  .vt-actions__cta {
    @include label-large;
    text-decoration: none;
    padding: 8px 0 0;
    cursor: pointer;

    &.next,
    &.close-tour {
      color: $brand-primary-400;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
