import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getLayout", "getLayoutUpdateTriggered"])
  },
  methods: {
    isUserFluidLayoutLoaded() {
      return (
        this.getLayout === "UserFluidLayout" &&
        this.getLayoutUpdateTriggered === false
      );
    },
    isSelfPacedLayoutLoaded() {
      return (
        this.getLayout === "main" && this.getLayoutUpdateTriggered === false
      );
    }
  }
};
