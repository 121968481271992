<template>
  <div
    class="lx-dashboard-section-heading"
    :class="{ 'is-link': isLink }"
    @click="handleClick"
  >
    <template v-if="hasIcon">
      <img
        :src="iconPath"
        :alt="iconFileName"
        class="lx-dashboard-section-heading__img"
      />
    </template>
    <slot></slot>
    <b-icon-chevron-right
      v-if="isLink"
      class="lx-dashboard-section-heading__icon"
    ></b-icon-chevron-right>
    <b-badge v-if="count" class="lx-dashboard-section-heading__badge">{{
      count
    }}</b-badge>
  </div>
</template>
<script>
export default {
  props: {
    iconFileName: {
      type: String,
      default: ""
    },
    routeName: {
      type: String,
      default: ""
    },
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    iconPath() {
      if (this.iconFileName !== "") {
        return require(`@/assets/images/dashboard/${this.iconFileName}`);
      }
      return "";
    },
    hasIcon() {
      return this.iconFileName !== "";
    },
    isLink() {
      return this.routeName !== "";
    }
  },
  methods: {
    handleClick() {
      if (this.isLink) {
        this.$router.push({
          name: this.routeName
        });
      }
    }
  }
};
</script>
<style lang="scss">
[dir="rtl"] {
  .lx-dashboard-section-heading {
    text-align: right;
    .lx-dashboard-section-heading__img {
      margin: 0 0 0 12px;
    }
    .lx-dashboard-section-heading__icon {
      margin: 0 15px 0 0;
      transform: rotate(-180deg);
    }
  }
}
.lx-dashboard-section-heading {
  text-align: left;
  color: $vle-primary-text;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  padding-left: 4px;
  .lx-dashboard-section-heading__img {
    margin-right: 12px;
  }
  .lx-dashboard-section-heading__icon {
    margin-left: 15px;
  }
  .lx-dashboard-section-heading__badge {
    color: $brand-secondary1-400 !important;
    background-color: $brand-secondary1-50 !important;
  }
  &.is-link {
    cursor: pointer;
    &:hover {
      color: $brand-primary-lighter;
    }
  }
}
</style>
