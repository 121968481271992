import axios from "axios";

axios.defaults.headers.common["Accept-Language"] = "en";

const getAuthHeaderV2WithCookie = (token, cookie) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json;version=2.0",
      Openedxcookie: JSON.stringify(cookie)
    }
  };
};

function getURL(baseURL, params) {
  return {
    //GET
    GET_TIMED_ASSESSMENT_DATA: `${baseURL}/courses/${params.courseId}/sequences/${params.sequenceId}/timed_assessment`,

    // PATCH
    UPDATE_TIMED_ASSESSMENT: `${baseURL}/courses/${params.courseId}/sequences/${params.sequenceId}/timed_assessment`
  };
}

export default {
  //GET
  getTimedAssessment({ commit, rootState }, params) {
    commit("SET_TIMED_DATA", {});
    commit("SET_TIMED_ASSESSMENT_FLAG", false);
    const URL = getURL(rootState.config.baseURL, params)
      .GET_TIMED_ASSESSMENT_DATA;
    const headers = getAuthHeaderV2WithCookie(
      this._vm.$keycloak.token,
      rootState.sessionCookie
    );
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_TIMED_DATA", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },

  // POST
  updateTimedAssessment({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params)
      .UPDATE_TIMED_ASSESSMENT;
    const headers = getAuthHeaderV2WithCookie(
      this._vm.$keycloak.token,
      rootState.sessionCookie
    );
    return axios
      .patch(URL, { state: params.state }, headers)
      .then(response => {
        commit("SET_TIMED_DATA", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  }
};
