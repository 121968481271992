/* eslint-disable no-unused-vars */
import axios from "axios";

axios.defaults.headers.common["Accept-Language"] = "en";

const getAuthHeader = token => {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
};
function downloadPdf(res) {
  const url = window.URL.createObjectURL(
      new Blob([res.data], {
        type: "application/pdf"
      })
    ),
    filename = `${
      res.headers["content-disposition"]
        .split('attachment; filename="')[1]
        .split(new RegExp("(.+?)(.[^.]+$|$)"))[1]
    }.pdf`;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}

export function getURL(baseURL, params = {}) {
  return {
    GET_INPROGRESS_COURSES: `${baseURL}/courses/enrolled?filter_by=continue_learning`,
    GET_STARTING_SOON_COURSES: `${baseURL}/courses/enrolled?filter_by=starting_soon`,
    GET_ARCHIVED_COURSES: `${baseURL}/courses/enrolled?filter_by=archived`,
    LIST_COMPLETED_COURSES: `${baseURL}/courses/completed`,
    LIST_ALL_CERTIFICATES: `${baseURL}/v2/certificates/courses`,
    GET_CERTIFICATE_DETAIL: `${baseURL}/v2/certificates/courses/${params.id}`,
    GET_PUBLIC_CERTIFICATE_DETAIL: `${baseURL}/v2/certificates/enrollments/${params.id}/public?detailed_credentials=${params.detailedCredentials}`,
    DOWNLOAD_CERTIFICATE: `${baseURL}/v2/certificates/courses/${params.id}/download/?include_micro_credentials=${params.includeMicroCredentials}`,
    DOWNLOAD_CREDENTIAL: `${baseURL}/v2/certificates/modules/${params.key}/download/`
  };
}

export default {
  getInProgressCourses({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).GET_INPROGRESS_COURSES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_SERVER_TIME", response.data.current_server_time);
        commit("SET_INPROGRESS_COURSES", response.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getStartingSoonCourses({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).GET_STARTING_SOON_COURSES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_SERVER_TIME", response.data.current_server_time);
        commit("SET_STARTINGSOON_COURSES", response.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getArchivedCourses({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).GET_ARCHIVED_COURSES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_SERVER_TIME", response.data.current_server_time);
        commit("SET_ARCHIVED_COURSES", response.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getCompletedCourses({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).LIST_COMPLETED_COURSES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_COMPLETED_COURSES", response.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getAllCertificates({ commit, rootState }) {
    const URL = getURL(rootState.config.baseURL).LIST_ALL_CERTIFICATES;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(response => {
        commit("SET_ALL_CERTIFICATES", response.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getCertificateDetail({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).GET_CERTIFICATE_DETAIL;
    const headers = getAuthHeader(this._vm.$keycloak.token);
    return axios
      .get(URL, headers)
      .then(res => {
        commit("SET_CERTIFICATE_DETAIL", res.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  getPublicCertificateDetail({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params)
      .GET_PUBLIC_CERTIFICATE_DETAIL;
    return axios
      .get(URL, {})
      .then(res => {
        commit("SET_CERTIFICATE_DETAIL", res.data.data);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  downloadCertificate({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).DOWNLOAD_CERTIFICATE;
    const headers = {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${this._vm.$keycloak.token}`
      }
    };
    return axios
      .get(URL, headers)
      .then(res => {
        downloadPdf(res);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  },
  downloadCredential({ commit, rootState }, params) {
    const URL = getURL(rootState.config.baseURL, params).DOWNLOAD_CREDENTIAL;
    const headers = {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${this._vm.$keycloak.token}`
      }
    };
    return axios
      .get(URL, headers)
      .then(res => {
        downloadPdf(res);
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data);
      });
  }
};
