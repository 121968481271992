<template>
  <div class="error-page h-100 d-flex flex-column align-items-center">
    <div class="logo">
      <img
        :src="
          require(`@/assets/images/logo/whoa-logo-black-${logoLanguage}.svg`)
        "
        height="48"
        alt="logo"
      />
    </div>
    <div class="coming-soon">
      <div class="error-page__img ">
        <img
          :src="require(`@/assets/images/coming-soon/devices.svg`)"
          alt="coming soon"
        />
      </div>
      <div class="ep-title">
        {{ $t("general.platform") }}
      </div>
      <div class="ep-subtitle">
        {{ $t("general.stay_tuned ") }}
      </div>
      <div class="ld-sec__image d-flex flex-column align-items-center">
        <img
          src="@/assets/images/landing/icons/app-store.svg"
          class="ld-sec__image--apple"
          width="148"
        />
        <img
          src="@/assets/images/landing/icons/play-store.svg"
          class="ld-sec__image--google"
          width="148"
        />
      </div>
      <LxpButton
        class="d-inline-flex text-uppercase"
        variant="outline-primary"
        @click="logOut"
      >
        {{ $t("my_profile.log_out.title") }}
      </LxpButton>
    </div>
  </div>
</template>
<script>
import { LxpButton } from "didactica";
import getLogo from "@/core/mixins/getLogo";
export default {
  components: { LxpButton },
  mixins: [getLogo],
  methods: {
    logOut() {
      localStorage.removeItem("onboardingStatus");
      const redirectUri =
        this.$keycloak.endpoints.logout() +
        "?id_token_hint=" +
        this.$keycloak.idToken +
        "&post_logout_redirect_uri=" +
        encodeURIComponent(window.location.origin);
      window.location.href = redirectUri;
    }
  }
};
</script>
<style lang="scss">
.error-page {
  overflow-y: auto;
  .logo {
    margin-top: 7.5px;
  }
  .coming-soon {
    margin: 32px 0px;
    width: calc(100% - 32px);
    box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
    border-radius: 16px;
    padding: 16px;
  }
  .ep-title {
    @include h6;
    color: $brand-neutral-1000;
    margin-top: 16px;
  }
  .ep-subtitle {
    @include body-regular;
    margin-top: 16px;
  }
  .ld-sec__image {
    margin-top: 32px;
    *:not(:first-child) {
      margin-top: 24px;
    }
  }
  .btn {
    margin: 48px 0px 16px;
  }
}

@media screen and (min-width: 744px) {
  .error-page {
    .logo {
      margin-top: 80px;
    }
    .coming-soon {
      padding: 32px 16px;
    }
    .ld-sec__image {
      flex-direction: row !important;
      justify-content: center;
      *:not(:first-child) {
        margin-top: 0;
        margin-left: 32px;
        width: auto;
        height: 49px;
      }
    }
    .btn {
      margin: 64px 0px 32px;
    }
  }
}
</style>
